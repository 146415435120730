<template>
    <div :id="uid" :style="(filterMode == 'ALL') ? 'display: block' : ((bookMarked == true) ? 'display: block' : 'display: none')" :class="(viewMode == 'GROUP') ? 'cfa-module col-12 col-md-3 mb-4' : 'cfa-module col-12 mb-4'">
        <div :id="uid+'-content'" :class="(viewMode == 'GROUP') ? 'p-3 bg-white cfa-course-module-inner cfa-module-shadow' : 'p-3 bg-white cfa-course-module-inner d-flex flex-row cfa-module-shadow'">
          <div>
            <div class="cfa-module-image position-relative" :id="uid+'-cts'" style="min-width: 14em; cursor: pointer" @click="changeLogo">
              <img v-if="preview != null" :src="preview" alt="cfa module course image"/>
              <img v-else style="width: 25% !important; height: 35% !important; min-height: auto;" src="../../../assets/Icons/All_Icon/Group-1.png" alt="cfa module course image"/>
            </div>
          </div>
          <div :class="(viewMode == 'GROUP') ? '' : 'ms-3 w-100'">
            <div class="py-2 pt-4">
              <h2 @click="selectText" style="height: 1.2em; font-size: 1.1em; text-align: center; overflow: hidden;"><strong>{{title.toUpperCase()}}</strong></h2>
            </div>
            <div style="height: 5em; text-align: center; overflow: hidden;" class="py-2 pb-3" v-html="description"></div>
            <div class="d-flex justify-content-between">
              <div></div>
              <div class="text-right mt-2">
                <router-link class="linke" :to="{name: 'ResourcesIframe', params: {id: this.id, slug: this.title.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}}">
                  <button class="btn cfa-btn-light-primary mx-1" title="Apperçu">
                    <i class="fa fa-open"></i>APPERÇU
                  </button>
                </router-link>
                <button @click="updateSRC" class="btn cfa-btn-primary mx-1" title="Modifier">
                  <i class="fa fa-edit"></i>
                </button>
                <button @click="deleteData" class="btn cfa-btn-danger mx-1" title="Supprimer">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--deletion box-->
        <scalable-modal @callback="tryDeleteSRC" :notifyTaskDone="callbackPayload" :uid="uid+'-modal'" @close="openDeletionBox=false" display-mode="flex" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" :style="'width: 30em; height: 22em;'">
          <template v-slot:content>
            <p>Etes vous sûr de vouloir supprimer cette ressource? <span style="color: red;">Cette Action est irreversible</span></p>
            <div class="col-12 pl-0">
              <div class="form-group py-3 pe-0 pe-md-2">
                <p class="mb-2"><strong>Entrez l'intitulé de la ressource pour confirmation</strong></p>
                <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
              </div>
            </div>
          </template>
        </scalable-modal>

        <!--update resource-->
        <scalable-modal @callback="updateResource" :break="exitResourceModal" label="UPDATE UNE RESSOURCE" :uid="uid+'-cfa-modal-src-update'" @close="newResource=false" :open="newResource" :advancedControl="true" validationButtonText="Mettre à jour" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
          <template v-slot:content>
            <!-- blo1 -->
            <div class="row w-100">
              <div class="form-group py-2 pe-0 pe-md-2 ">
                <p class="mb-2"><strong>TITRE</strong></p>
                <div class="d-flex flex-row">
                  <input class="form-control" type="text" v-model="src_title" @input="limitInput">
                </div>
              </div>
              <div class="mt-2">
                <p class="mb-2"><strong>DESRIPTION</strong></p>
                <textarea class="descriptionArea" v-model="src_description"></textarea>
              </div>
              <div class="form-group py-2 pe-0 pe-md-2 ">
                <p class="mb-2"><strong>ATTACHED MODULE</strong></p>
                <select class="form-control" v-model="src_module">
                  <option value="default" selected disabled hidden>Attach a tag</option>
                  <option v-for="module in allModules" v-bind:key="module.id" :value="module.id">{{ module.title }}</option>
                </select>
              </div>
            </div>

            <div class="row w-100 mt-3">
              <div class="form-group py-3 pe-0 pe-md-2 ">
                <p class="mb-2"><strong>FICHIER PDF</strong></p>
                <document-input @pdf-loaded="onPdfLoaded"/>
              </div>
            </div>
          </template>
        </scalable-modal>

        <!--alert box-->
        <transition name="fadeY" appear mode="out-in">
          <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
        </transition>

        <!-- file uploader -->
        <input type="file" ref="fileSelector" @change="uploadImage" :id="'uploader-'+uid" style="display: none;">

    </div>
</template>

<script>
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import {getAPI} from "@/axios-api";
import Alert from '@/components/shared/alert/Alert.vue'
import DocumentInput from '@/components/shared/input/DocumentInput.vue'

export default {
  name: 'CourseCard2',
  components: {
    ScalableModal,
    Alert,
    DocumentInput
  },
  props: {
    uid: {
      type: String,
      default: 'cfa-course-01'
    },
    link: {
      type: String,
      default: '#'
    },
    preview: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'FORENSIC'
    },
    id: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: 'Sécurité du post de travail.'
    },
    description: {
      type: String,
      default: 'lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem'
    },
    started: {
      type: Boolean,
      default: true
    },
    max__horaire: {
      type: String,
      default: '22'
    },
    viewMode: {
      type: String,
      default: 'GROUP'
    },
    filterMode: {
      type: String,
      default: 'ALL'
    },
    marked: {
      type: Boolean,
      default: true
    },
    module: {
      type: String,
    },
    allModules: {
      type: Object,
    }
  },
  data () {
    return {
      bookMarked: this.marked,
      available: false,
      openDeletionBox: false,
      name_check: '',
      callbackPayload: '',

      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',

      newResource: false,

      src_title: this.title,
      src_description: this.description,
      src_module: this.module,
      src_pdf: null,
      exitResourceModal: null
    }
  },
  methods: {
    displayError(error, type='alert-ok', time=5000) {
      this.gAlertMessage = error
      this.gAlertType = type
      this.alertMe = true
      setTimeout(() => {
        this.alertMe = false
      }, time);
    },
    bookMark () {
      document.getElementById(this.uid+'-cts').style.backgroundColor='#00bfc6';
      setTimeout(() => {
        document.getElementById(this.uid+'-cts').style.backgroundColor='transparent';
      }, 500);
      this.bookMarked = !this.bookMarked
      // --------
      // API CALL
      // --------
    },
    goTo () {
      let a = document.createElement('a')
      a.href=this.link
      a.click()
    },
    deleteData () {
      this.openDeletionBox = true
    },
    publishModule () {
      this.available = !this.available
      this.$emit('publish', this.available)
    },
    selectText (event) {
      window.getSelection().selectAllChildren(event.target);
    },
    tryDeleteSRC () {
      if(this.name_check != this.title) {
        this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
      }
      else{
        getAPI.delete(`ressources/${this.id}/`)
            .then(response => {
              if(response.status == 204) {
                this.callbackPayload = 'Ressource supprimé avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.$emit('sync')
                }, 1500)
              }
              else {
                this.displayError('Oups ! something went wrong.', 'alert-no')
                this.openLogoutBox = false
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
      }
    },
    updateSRC () {
      this.newResource = true
    },
    onPdfLoaded (pdf_bin) {
      this.src_pdf = pdf_bin
    },
    updateResource () {
      if(this.src_title != '' && this.src_module != '' && this.src_description != '') {
        const formData = new FormData();
        formData.append('title', this.src_title);
        formData.append('title_en', this.src_title);
        formData.append('description', this.src_description);
        formData.append('description_en', this.src_description);
        formData.append('module', this.src_module);
        if(this.src_pdf != null) {
          formData.append('resources_link', this.src_pdf);
        }
        getAPI.patch(`ressources/${this.id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          if(response.status == 200) {
            this.$emit('update');
            let msg = 'Resource MAJ avec succès!'
            this.displayError(msg, 'alert-yes', 5000)
          }
          this.exitResourceModal = Date.now()
        }).catch((error) => {
          if (error.response) {
            this.displayError(error.response.data.message, 'alert-no', 10000)
          }
          else if (error.request) {
            this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 10000)
          }
          else {
            this.displayError('Oups ! something went wrong.', 'alert-no', 10000)
          }
          this.exitResourceModal = Date.now()
        });
      }
      else{
        this.displayError('Formulaire invalide ou incomplet', 'alert-no', 10000)
        this.alertString = `Formulaire invalide ou incomplet|alert-no|10000::`+Date.now()
        this.exitResourceModal = Date.now()
      }
    },
      changeLogo () {
          document.getElementById(`uploader-${this.uid}`).click();
      },
      uploadImage () {
        const file = document.getElementById(`uploader-${this.uid}`).files[0];
        const formData = new FormData();
        formData.append('resources_image', file);

        this.uploadPending = true
        getAPI.put(`ressources/upload_image/${this.id}/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(() => {
            window.location.reload();
        }).catch(() => {
            alert("Fichier trop large.")
            /*this.alertString = "|alert-no|5000::"+Date.now()
            this.uploadPending = false
            console.log(err)*/
        });
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.cfa-course-module-inner{
  border-radius: .5em;
}
.cfa-module-image{
  /* width: 500px; */
  border: 2px solid #f0f1f6;
  border-radius: .5em;
  overflow: hidden;
  min-height: 14em;
  max-height: 14em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cfa-module-image img{
  width: 100%;
  min-height: 200px;
}
.gType{
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.gType2{
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.cfa-module{
  transition: all ease-in .3s;
}
.cfa-module-shadow{
  /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.linke{
  color: #000;
  text-decoration: none;
}
.linke:hover{
  text-decoration: underline;
  color: #00bfc6;
}
.descriptionArea{
  height: 135px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  outline: currentcolor none medium !important;
  border: 1px solid rgb(204, 204, 204);
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
  padding: 6px 12px;
  background: rgb(247, 248, 252) none repeat scroll 0% 0%;
}
</style>

<template>
  <div class="cfa-newsletter-wrapper">
    <input type="email" name="newsletter" placeholder="Your email" v-model="email"/>
    <button class="btn cfa-btn-primary cfa-subscrib-btn" @click="openPopup" :disabled="!isValidEmail">Subscribe</button>

    <!-- Popup -->
<!--    <div v-if="isPopupOpen" class="popup-wrapper">-->
<!--      <div class="popup-content">-->
<!--        <h2>Please enter your details</h2>-->
<!--        <input type="text" name="firstname" placeholder="First name" v-model="firstname"/>-->
<!--        <input type="text" name="lastname" placeholder="Last name" v-model="lastname"/>-->
<!--        <button class="btn cfa-btn-primary cfa-subscrib-btn" @click="submitData">Submit</button>-->
<!--        <button class="btn cfa-btn-secondary cfa-subscrib-btn" @click="closePopup">Cancel</button>-->
<!--      </div>-->
<!--    </div>-->

    <scalable-modal @callback="postEmail" :break="exitModal" uid="cfa-modal-newsletter " display-mode="flex" @close="isPopupOpen=false" :open="isPopupOpen" :advancedControl="true" validationButtonText="Valider" exitButtonText="Annuler" :style="'width: 32em; height: auto;'">
      <template v-slot:content>
        <div class="form-group w-100">
          <label class="control-label text-dark">Votre Nom</label>
          <div id="studentList">
            <div class="input-group">
              <input class="form-control newsletterInput" type="text"  v-model="firstname" placeholder="John">
            </div>
          </div>
        </div>
        <div class="form-group w-100">
          <label class="control-label text-dark">Votre Prénom</label>
          <div id="studentList">
            <div class="input-group">
              <input class="form-control newsletterInput" type="text"  v-model="lastname" placeholder="DOE">
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>
import scalableModal from "@/components/shared/modal/ScalableModal.vue";
import {getAPI} from "@/axios-api";

export default {
  name: 'NewsLetter',
  components: {
    scalableModal
  },
  data() {
    return {
      email: '',
      firstname: '',
      lastname: '',
      isPopupOpen: false,
      exitModal: null,
    };
  },

  computed: {
    isValidEmail() {
      // regex for email validation
      const regex = /\S+@\S+\.\S+/;
      return regex.test(this.email);
    }
  },

  methods: {
    openPopup() {
      if (this.isValidEmail) {
        this.isPopupOpen = true;
      }
    },

    postEmail() {
      if(this.firstname != '' && this.lastname != '') {
        const data = {
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname
        };

        getAPI.post('newsletter/', data)
            .then(response => {
              if(response.status == 201) {
                alert('Merci ! Votre inscription a été pris en compte.')
                this.exitModal = new Date();
              }
            })
            .catch(() => {
              this.exitModal = new Date();
              // handle error response
              alert('Oups ! Une erreur est survenue.')
            });
      }
    }
  }
}
</script>

<style scoped>
.cfa-newsletter-wrapper{
  /*width: 21em;*/
  position: relative;
}
.cfa-newsletter-wrapper input{
  outline: none !important;
  border-radius: .5em;
  padding: .8em 1.2em;
  width: 100%;
  border: none;
}
.cfa-subscrib-btn{
  position: absolute;
  top: .25em;
  bottom: .25em;
  right: .25em;
}
.newsletterInput{
  background: #f4f4f4;
  border: 1px solid #00ced5 !important;
  margin-bottom: .7em;
}
</style>

<template>
  <div class="image-upload-container">
    <div class="image-upload-square" @dragover="onDragOver" @drop="onDrop" @click="onClick" >
      <input type="file" ref="fileInput" accept="image/*" @change="onFileChange" style="display: none;" />
      <img v-if="image" :src="image"  @load="onImageLoad"/>
      <div v-else class="px-4">
        Drag an image here or click to select a file to upload.
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ImageInput',
    data() {
        return {
          image: null,
          binFile: null
        };
    },
    methods: {
        onDragOver(event) {
            event.preventDefault();
        },
        onDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            this.handleFile(file);
        },
        onClick() {
            this.$refs.fileInput.click();
        },
        onFileChange(event) {
            const file = event.target.files[0];
            this.binFile = file;
            this.handleFile(file);
        },
        handleFile(file) {
            if (!file) return;
            if (!file.type.startsWith("image/")) {
                // handle invalid file type
                return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
                this.image = event.target.result;
            };
            reader.readAsDataURL(file);
        },
        onImageLoad() {
          this.$emit('image-loaded', this.$refs.fileInput.files[0]);
        },
    },
};
</script>

<style>
    .image-upload-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image-upload-square {
        width: 100%;
        height: 200px;
        background-color: #f5f5f5;
        border: 3px dashed #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 6px;
        transition: all .2s ease-in;
        cursor: pointer;
    }

    .image-upload-square:hover{
        border-color: #06bbc4;
        background-color: #fff;
    }

    .image-upload-square img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>

<template>
  <div class="cfa-root">
    <c-f-a-nav>
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3">
          <h1 class="cfa-section-title">GESTION DES UTILISATEURS</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <div class="d-flex justify-content-between align-items-center">
            <search-bar cssStyle="background-color: #fff; border: 1px solid #e5e8f2;"></search-bar>
            <select-button @action="catchAction" label="AJOUTER" :dropDownLabel="['Group d\' Utilisateur', 'Un utilisateur']" :dropDownLink="['#user-group', '#unique-user']" cssStyle="background-color: #06bbc4 !important; color: #fff !important;"></select-button>
          </div>
          <div class="w-100 mt-4" style="overflow-x: scroll;"> 
            <div class="col-12 pe-0" style="min-width: 800px;">
              <!-- header -->
              <div class="cfa-block ml-0 gCorner-1100">
                <div class="py-2 row">
                  <div class="text-start col-1 ps-4 text-start"><strong class="cfa-primary">#</strong></div>
                  <div class="text-start col-2"><strong class="cfa-primary">Nom</strong></div>
                  <div class="text-start col-2"><strong class="cfa-primary">Prénoms</strong></div>
                  <div class="text-start col-1"><strong class="cfa-primary">Téléphone</strong></div>
                  <div class="d-none d-md-block text-start col-2"><strong class="cfa-primary">Email</strong></div>
                  <div class="text-end text-md-start col-2"><strong class="cfa-primary">Entreprise</strong></div>
                  <div class="text-end text-md-start col-2"><strong class="cfa-primary">Options</strong></div>
                </div>
              </div>
              <!-- table -->
              <div class="cfa-block ml-0 gCorner-0011" style="box-shadow: none; margin-top: .2em;">
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div> 
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div>
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src="https://picsum.photos/101" alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-2">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-2">
                    OlaSoft
                  </div>
                  <div class="text-start col-2 ms-5 ms-md-0">
                    <div class="d-flex">
                      <icon-button @onclick="updateData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-edit cfa-primary"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="updateVisibility(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-eye"></i>
                        </template>
                      </icon-button>
                      <icon-button @onclick="deleteData(1)">
                        <template v-slot:icon>
                          <i style="font-size: 1.5em;" class="fa fa-trash cfa-danger"></i>
                        </template>
                      </icon-button>
                    </div>
                  </div>
                </div> 
              </div>
              <!-- paginator -->
              <div class="cfa-paginator">
                <div class="mb-4">
                  <ul class="pagination float-end">
                    <li class="page-item page-prev disabled">
                      <a class="page-link" href="javascript:void(0)" tabindex="-1">Prev</a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="javascript:void(0)">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">5</a>
                    </li>
                    <li class="page-item page-next">
                      <a class="page-link" href="javascript:void(0)">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- popup -->
              <div class="gPopupZone">
                <!-- 01 -->
                <scalable-modal uid="cfa-modal-002" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" :style="'width: 30em; height: 22em;'">
                  <template v-slot:content>
                    <p>Etes vous sûr de vouloir supprimer cet utilisateur? <span style="color: red;">Cette Action est irreversible</span></p>
                    <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Entrez votre mot de passe</strong></p>
                        <input class="form-control" type="password" autocomplete="new-password">
                      </div>
                    </div>
                  </template>
                </scalable-modal>
                <!-- 02 -->
                <scalable-modal uid="cfa-modal-003" @close="openUpdateBox=false" :open="openUpdateBox" :advancedControl="true" validationButtonText="Mette A Jour" exitButtonText="Annuler" :style="'width: 75%; height: auto;'">
                  <template v-slot:content>
                    <p><span style="color: red; font-weight: bold;">Mise a jour de données utilisateur</span></p>
                    <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Email</strong></p>
                        <input class="form-control" type="password" autocomplete="new-password" disabled>
                      </div>
                    </div>
                    <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Nom</strong></p>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Prénom</strong></p>
                        <input class="form-control" type="text">
                      </div>
                    </div>
                    <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Téléphone</strong></p>
                        <input class="form-control" type="phone">
                      </div>
                    </div>
                    <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Entreprise</strong></p>
                        <input class="form-control" type="phone">
                      </div>
                    </div>
                    <!-- <div class="col-12 pl-0">
                      <div class="form-group py-3 pe-0 pe-md-2">
                        <p class="mb-2"><strong>Plan</strong></p>
                        <div class="input-group">
                          <select name="duration" class="form-control" style="width: 100%;">
                            <option value="15">Selectionner</option>
                            <option value="15">Vente et restauration</option>
                            <option value="30">Développement logiciel</option>
                            <option value="45">Sécurité Informatique</option>
                            <option value="60">Autres</option>
                          </select>
                        </div>
                      </div>
                    </div> -->
                  </template>
                </scalable-modal>
                <!-- 03 -->
                <scalable-modal uid="cfa-modal-004" @close="addGroup=false" :open="addGroup" :advancedControl="true" validationButtonText="Ajouter la list d'utilisateur" exitButtonText="Annuler">
                  <template v-slot:content>
                    <form action="" method="post" enctype="multipart/form-data" style="width: 100%;">
                      <div id="collapse-day-details" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div class="card-body _p0">
                          <div class="form-group ">
                            <span class="d-flex align-items-center justify-content-between">
                              <label class="control-label" for="inputPassnew">Coller dans le champ çi-dessous une liste des adresses emails (Une adresse email par ligne)</label>
                            </span>
                            <br>
                            <div style="min-height: 15vh; width: 100%;" id="studentList">
                              <div class="input-group">
                                <textarea id="mailListContainer" @keyup="checkMailList($event)" v-model="listOfEmail" style="min-height: 25em;" class="form-control" ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </template>
                </scalable-modal>
                <!-- 04 -->
                <scalable-modal uid="cfa-modal-005 " @close="addUser=false" :open="addUser" :advancedControl="true" validationButtonText="Ajouter" exitButtonText="Annuler" :style="'width: 55%; height: auto;'">
                  <template v-slot:content>
                    <div class="form-group w-100">
                      <br>
                      <span class="d-flex align-items-center justify-content-between">
                        <label class="control-label" for="inputPassnew">Ajouter l'adresse email</label>
                      </span>
                      <div style="min-height: 15vh" id="studentList">
                        <div class="input-group">
                          <input id="inputPassnew" class="form-control" name="inputPassnew" type="email"  required="">
                        </div>
                      </div>
                    </div>
                  </template>
                </scalable-modal>
              </div>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import SearchBar from '@/components/shared/SearchBar.vue'
import SelectButton from '@/components/shared/button/SelectButton.vue'
import IconButton from '@/components/shared/button/IconButton.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'

export default {
  name: 'UserManagmentView',
  components: {
    CFAFooter,
    CFANav,
    SearchBar,
    SelectButton,
    IconButton,
    ScalableModal
  },
  data () {
    return {
      openDeletionBox: false,
      openUpdateBox: false,
      addUser: false,
      addGroup: false,
      listOfEmail: []
    }
  },
  methods: {
    updateData (i) {
      console.log(i)
      this.openUpdateBox = true
    },
    updateVisibility (i) {
      console.log(i)
    },
    deleteData (i) {
      console.log(i)
      this.openDeletionBox = true
    },
    catchAction (data) {
      if(data == '#user-group') {
        this.addGroup = true
      }
      else if(data == '#unique-user') {
        this.addUser = true
      }
    },
    checkMail (mail) {
      if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true
      }
      return false
    },
    checkMailList (e) {
      if(e.keyCode === 32) {
        this.listOfEmail += '\n';
      }
      let data = this.listOfEmail.split('\n')
      document.getElementById('mailListContainer').style.borderColor = '#b9f5b9'
      for(let i=0; i<data.length; i++) {
        if(!this.checkMail(data[i])) {
          document.getElementById('mailListContainer').style.borderColor = '#ff0000'
          break
        }
      }
      console.log(data)
    }
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.cfa-avatar1{
  cursor: pointer;
  /* padding: 0.5em; */
  border-radius: .5em;
  /* background-color: #f0f3f8; */
  transition: all ease-in .1s;
  margin-left: 0.5em;
}
.cfa-avatar1:hover{
  background-color: #eceff5;
}
.cfa-avatar1 img{
  border-radius: 100%;
  margin: 0.5em;
}
.cfa-user-progress-line{
  border-bottom: 1px solid #eceff5;
}
.cfa-user-progress-line:last-of-type{
  border-bottom: none;
}
.cfa-user-progress-line:hover{
  box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
}
</style>
<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title d-none d-md-block">IMAGES PUBLICITAIRES</h1>
          <h1 class="cfa-section-title d-md-none d-block">PUBS</h1>
          <button @click="this.newPub=true" class="btn cfa-btn-primary d-flex align-items-center">
            <span class="pt-0">AJOUTER</span> &nbsp; <i class="fa fa-plus">&nbsp;</i>
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page pt-1">
          <div class="col-12 pe-0 ">
            <!-- ********************** -->
            <Table :columns="pubsColumns" :data="pubs" :columns-action="pubsToListen" @action="getCallToAction1">
              <select class="form-control" name="screen" v-model="pageFilter" @change="getPubs" style="width: 14em;">
                <option value="null">--</option>
                <option value="CONNEXION">PUBS PAGE - CONNEXION</option>
                <option value="INSCRIPTION">PUBS PAGE - INSCRIPTION</option>
                <option value="ASK_PASSWORD">PUBS PAGE - MOT DE PASSE OUBLIÉ</option>
                <option value="RST_PASSWORD">PUBS PAGE - RÉINITIALISATION DE MOT DE PASSE</option>
                <option value="ACTIVATE">PUBS PAGE - ACTIVATION DE COMPTE</option>
                <option value="REACTIVATE">PUBS PAGE - REACTIVATION DE COMPTE</option>
              </select>
            </Table>
            <!-- ********************** -->
          </div>
          <br>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>

    <!-- create -->
    <scalable-modal @callback="addNewPub" :break="exitRoleModal" display-mode="flex" label="CRÉER UN PLAN" uid="cfa-modal-org-create" @close="newPub=false" :open="newPub" :advancedControl="true" validationButtonText="Ajouter" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>ECRAN D'AFFICHAGE</strong></p>
            <select class="form-control" name="screen" v-model="pub_key">
              <option value="null">--</option>
              <option value="CONNEXION">CONNEXION</option>
              <option value="INSCRIPTION">INSCRIPTION</option>
              <option value="ASK_PASSWORD">MOT DE PASSE OUBLIÉ</option>
              <option value="RST_PASSWORD">RÉINITIALISATION DE MOT DE PASSE</option>
            </select>
          </div>
          <div class="form-group py-2 pe-0 ">
            <p class="mb-2"><strong>DESCRIPTION</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text" v-model="pub_desc">
            </div>
          </div>
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>IMAGE PUBLICITAIRE</strong></p>
            <image-input @image-loaded="onImageLoaded"/>
          </div>
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>LIEN DE REDIRECTION</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text" v-model="pub_link">
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- update permissions -->
    <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" display-mode="flex" label="CRÉER UN PLAN" uid="cfa-modal-role-update" @close="openUpdatePerm=false" :open="openUpdatePerm" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-show="waitingAPIResponse2" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
        <div v-show="!waitingAPIResponse2" class="row w-100">
          <div class="form-group py-2 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>UPDATE PUB</strong></p>
            <div class="d-flex flex-column">
              <div class="form-group py-2 pe-0">
                <p class="mb-2"><strong>ECRAN D'AFFICHAGE</strong></p>
                <select class="form-control" name="screen" v-model="oldPub.key">
                  <option value="null">--</option>
                  <option value="CONNEXION">CONNEXION</option>
                  <option value="INSCRIPTION">INSCRIPTION</option>
                  <option value="ASK_PASSWORD">MOT DE PASSE OUBLIÉ</option>
                  <option value="RST_PASSWORD">RÉINITIALISATION DE MOT DE PASSE</option>
                </select>
              </div>
              <div class="form-group py-2 pe-0 ">
                <p class="mb-2"><strong>DESCRIPTION</strong></p>
                <div class="d-flex flex-row">
                  <input class="form-control" type="text" v-model="oldPub.description">
                </div>
              </div>
              <div class="form-group py-3 pe-0">
                <p class="mb-2"><strong>IMAGE PUBLICITAIRE</strong></p>
                <image-input @image-loaded="onImageLoadedForUpdate"/>
              </div>
              <div class="form-group py-2 pe-0">
                <p class="mb-2"><strong>LIEN DE REDIRECTION</strong></p>
                <div class="d-flex flex-row">
                  <input class="form-control" type="text" v-model="oldPub.link">
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- delete -->
    <scalable-modal @callback="confirmDeletion1" :break="exitRoleModalDel" :notifyTaskDone="callbackPayload" :uid="'general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
      <template v-slot:content>
        <p>Etes vous sûr de vouloir supprimer cette image publicitaire? <span style="color: red;">Cette Action est irreversible</span></p>
        <div class="col-12 pl-0">
          <div class="form-group py-3 pe-0 pe-md-2 d-flex align-items-center">
            <input id="deleteIt" type="checkbox" v-model="pub_deletion_check">
            <label class="ms-2" for="deleteIt" ><strong>Je sais ce que je fais </strong></label>
          </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import {getAPI} from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";
import ImageInput from "@/components/shared/input/ImageInput.vue";

export default {
  name: 'PubView',
  components: {
    CFAFooter,
    CFANav,
    ScalableModal,
    Table,
    ImageInput
  },
  data () {
    return {
      pub_image: '',
      pub_desc: '',
      pub_link: '',
      pub_key: '',
      pubs: [],
      pubsColumns: ['image', 'Description', 'Lien à suivre', 'Autres'],
      pubsToListen: ['Autres'],
      pageFilter: 'CONNEXION',
      pub_image_update: '',
      pub_deletion_check: '',

      waitingAPIResponse: true,
      waitingAPIResponse2: true,
      waitingAPIResponse3: true,
      openDeletionBox: false,
      tempDeleteID: '',
      tempDeleteName: '',
      openUpdatePerm: false,
      oldPub: [],
      tempUpdatePudId: null,
      newPub: false,
      exitRoleModal: null,
      alertString: '',
      // ??
      callbackPayload: null,
      exitRoleModalDel: null,
      exitOrgUpdateModal: null,
    }
  },
  methods: {
    onImageLoaded (imageData) {
      this.pub_image = imageData
    },
    onImageLoadedForUpdate (imageData) {
      this.pub_image_update = imageData
    },
    addNewPub () {
      if(this.pub_image != '' && this.pub_desc != '' && this.pub_key != '' && this.pub_link) {
        const formData = new FormData();
        formData.append('key', this.pub_key);
        formData.append('description', this.pub_desc);
        formData.append('description_en', this.pub_desc);
        formData.append('link', this.pub_link);
        formData.append('image', this.pub_image);

        getAPI.post('pub/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if(response.status == 201) {
            this.pageFilter = this.pub_key
            this.getPubs()
            let msg = 'Pub ajouté avec succès!'
            this.alertString = `${msg}|alert-yes|7000::`+Date.now()
            this.exitRoleModal = Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitRoleModal = Date.now()
        }).catch(error => {
            if (error.response) {
              let firstError = Object.values(error.response.data)[0][0].replace(' en ', " ")
              this.alertString = `${firstError}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitRoleModal = Date.now()
        })
      }
      else{
        let msg = 'Oups ! Veuillez remplir les champs convenablement.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitRoleModal = Date.now()
      }
    },
    getPubs () {
      this.waitingAPIResponse = true
      getAPI.get(`pub?key=${this.pageFilter}`)
        .then(response => {
          if(response.status == 200) {
            let pubs = response.data;
            this.pubs = []
            for(let i=0; i<pubs.length; i++) {
              let row = pubs[i]
              let link = `<a style="color: #0d51f1; font-weight: bold;" href="${row.link}" target="_blank"><i class="fa fa-link"></i> ${row.link}</a>`
              let update = this.formatDate(row.updated_at)
              let btn = `<div class="d-flex align-items-center">
                            <button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-primary btn-action">MODIFIER</button>
                            <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-trash"></i></button>
                         </div>`
              let image = `<image src="${row.image}" style="width: 22em; height: 22em; overflow: hidden;"/>`
              this.pubs.push({'image': image, "Ecran d'affichage": row.key, 'Description': row.description, 'Lien à suivre': link, 'Dernière modification':update, 'Autres': btn, name: row.link})
            }
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    getCallToAction1 (payload) {
      if(payload.action_type == 'delete') {
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      }
      if(payload.action_type == 'view') {
        this.updateData(payload.id);
      }
    },
    updateData (id) {
      this.tempUpdatePudId = id
      this.waitingAPIResponse2 = true
      this.openUpdatePerm = true
      getAPI.get(`pub/${id}/`)
        .then(response => {
          if(response.status == 200) {
            let pub = response.data;
            this.oldPub = pub;
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse2 = false
          }, 2000);
        })
        .catch(error => {
          if (error.response) {
            this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.waitingAPIResponse2 = false
        })
    },
    confirmUpdate () {
      const clone = Object.assign({}, this.oldPub);
      delete clone.image;
      if(this.pub_image_update != '') {
        clone.image = this.pub_image_update;
      }
      getAPI.patch(`pub/${this.tempUpdatePudId}/`, clone, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
          if(response.status == 200) {
            let msg = 'Pub MAJ avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitOrgUpdateModal = Date.now()
            this.getPubs()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal = Date.now()
        })
        .catch(error => {
          if (error.response) {
            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal = Date.now()
        })
    },
    confirmDeletion1 () {
      if(!this.pub_deletion_check) {
        this.callbackPayload = 'Veuillez confirmer cette action.' + '::-1::' + Date.now();
      }
      else{
        getAPI.delete(`pub/${this.tempDeleteID}`)
          .then(response => {
            if(response.status == 204) {
              this.callbackPayload = 'Pub supprimé avec succès' + '::' + Date.now();
              setTimeout(() => {
                this.getPubs()
              }, 1500)
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
              this.exitRoleModalDel = Date.now()
            }
          })
          .catch(() => {
            this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
          })
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },
  },
  mounted() {
    this.getPubs()
  }
}
</script>

<style scoped>
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-certif-state-line:hover{
    /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-progress-status{
  min-width: 7em;
}
.item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 0.25em;
}

/* TAB STYLE */
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 10px;*/
  /*border-bottom: 1px solid #ccc;*/
}

.tab-header ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-header ul li {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.tab-header ul li.active {
  background-color: #333;
  color: #fff;
}

.tab-body {
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 0 .5em .5em .5em;
  min-height: 10em;
  /*background-color: #fff;*/
}
</style>

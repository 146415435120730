<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title d-none d-md-block">IDENTITY AND ACCESS MANAGEMENT</h1>
          <h1 class="cfa-section-title d-md-none d-block">IAM</h1>
          <div class="d-flex align-items-center">
            <button v-if="currentTab == 'roles'" @click="this.newRole=true" class="btn cfa-btn-primary d-flex align-items-center">
              <span class="pt-1">NOUVEAU ROLE</span> &nbsp; <i class="fa fa-plus">&nbsp;</i>
            </button>
            <button v-else class="btn cfa-btn-primary disabled d-flex align-items-center">
              <span class="pt-1">NOUVEAU ROLE</span> &nbsp; <i class="fa fa-plus">&nbsp;</i>
            </button>
            <button v-if="currentTab == 'user'" @click="this.newOrgMember=true" class="ms-1 btn cfa-btn-primary d-flex align-items-center">
              <span class="pt-1">&nbsp;</span> <i class="fa fa-user-plus">&nbsp;</i>
            </button>
            <button v-else class="ms-1 btn cfa-btn-primary disabled d-flex align-items-center">
              <span class="pt-1">&nbsp;</span> <i class="fa fa-user-plus">&nbsp;</i>
            </button>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page pt-1">
          <div class="col-12 pe-0 ">
            <!-- ********************** -->
            <div class="tab">
              <div class="tab-header">
                <ul>
                  <li :class="{ 'active': currentTab === 'roles' }" @click="currentTab = 'roles'">Roles</li>
                  <li :class="{ 'active': currentTab === 'permissions' }" @click="currentTab = 'permissions'">Permissions</li>
                  <li :class="{ 'active': currentTab === 'user' }" @click="currentTab = 'user'">Utilisateurs</li>
                </ul>
              </div>
              <div class="tab-body">
                <div v-if="currentTab === 'roles'">
                  <Table :columns="rolesColumns" :data="roles" :columns-action="rolesToListen" @action="getCallToAction1"></Table>
                </div>
                <div v-if="currentTab === 'permissions'">
                  <Table :columns="permissionsColumns" :data="permissions" :columns-action="permissionsToListen"></Table>
                </div>
                <div v-if="currentTab === 'user'">
                  <Table last-cell-width="115px" :columns="usersColumns" :data="users" :columns-action="usersToListen" @action="getCallToAction3"></Table>
                </div>
              </div>
            </div>
            <!-- ********************** -->
          </div>
          <br>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>

    <!-- create -->
    <scalable-modal @callback="addNewRole" :break="exitRoleModal" display-mode="block" label="CRÉER UN PLAN" uid="cfa-modal-org-create" @close="newRole=false" :open="newRole" :advancedControl="true" validationButtonText="Ajouter" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div class="row w-100">
          <div class="form-group py-2 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>INTITUTLÉ</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text" v-model="role_name">
            </div>
          </div>
          <div class="d-flex flex-column">
            <p class="mb-2"><strong>PERMISSIONS</strong></p>
            <div v-for="(permission) in permissions" :key="permission.id" class="d-flex align-items-center">
              <input type="checkbox" :id="'permission-' + permission.id" :value="permission.id" v-model="newPermission">
              <label :for="'permission-' + permission.id" class="ms-2 text-uppercase">{{ permission.name }}</label>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- update permissions -->
    <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-role-update" @close="openUpdatePerm=false" :open="openUpdatePerm" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-show="waitingAPIResponse2" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
        <div v-show="!waitingAPIResponse2" class="row w-100">
          <div class="form-group py-2 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>PERMISSIONS</strong></p>
            <div class="d-flex flex-column">
              <div v-for="(permission) in permissions" :key="permission.id" class="d-flex align-items-center">
                <input type="checkbox" :id="'permission-' + permission.id" :value="permission.id" v-model="oldPermission">
                <label :for="'permission-' + permission.id" class="ms-2 text-uppercase">{{ permission.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- update user role-->
    <scalable-modal @callback="confirmUpdate3" :break="exitOrgUpdateModal3" display-mode="flex" label="CRÉER UN PLAN" uid="cfa-modal-role-update1" @close="openUpdateUserRole=false" :open="openUpdateUserRole" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-show="waitingAPIResponse3" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
        <div v-show="!waitingAPIResponse3" class="row w-100">
          <div class="form-group py-2 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>ROLE</strong></p>
            <div class="d-flex flex-column">
              <div v-for="(role) in roles" :key="role.id" class="d-flex align-items-center">
                <input type="radio" :id="'permission-' + role.id" :value="role.id" v-model="tempUpdateUserRoleId" >
                <label :for="'permission-' + role.id" class="ms-2 text-uppercase">{{ role.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- delete -->
    <scalable-modal @callback="confirmDeletion1" :break="exitRoleModalDel" :notifyTaskDone="callbackPayload" :uid="'general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
      <template v-slot:content>
        <p>Etes vous sûr de vouloir supprimer ce role? <span style="color: red;">Cette Action est irreversible</span></p>
        <div class="col-12 pl-0">
          <div class="form-group py-3 pe-0 pe-md-2">
            <p class="mb-2"><strong>Entrez l'intitulé du role pour confirmation</strong></p>
            <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check1">
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- invite member -->
    <scalable-modal @callback="addNewMember" :break="exitOrgMemberModal" display-mode="flex" label="CRÉER UN PLAN" uid="cfa-modal-org-member-invite" @close="newOrgMember=false" :open="newOrgMember" :advancedControl="true" validationButtonText="Ajouter" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>EMAIL</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text" v-model="new_member_email">
            </div>
          </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import {getAPI} from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";

export default {
  name: 'OrganisationView',
  components: {
    CFAFooter,
    CFANav,
    ScalableModal,
    Table
  },
  data () {
    return {
      newOrgMember: false,
      currentTab: 'roles',
      waitingAPIResponse: true,
      waitingAPIResponse2: true,
      waitingAPIResponse3: true,
      roles: [],
      rolesColumns: ['n°', 'Intitulé', 'Dernière modification', 'Autres'],
      rolesToListen: ['Autres'],
      name_check1: '',
      openDeletionBox: false,
      tempDeleteID: '',
      tempDeleteName: '',
      permissions: [],
      permissionsColumns: ['n°', 'Intitulé', 'Description', 'Dernière modification'],
      permissionsToListen: [],
      openUpdatePerm: false,
      oldPermission: [],
      newPermission: [],
      tempUpdateRoleId: null,
      newRole: false,
      exitRoleModal: null,
      role_name: '',
      alertString: '',
      users: [],
      usersColumns: ['n°', 'Nom & Prénoms', 'Email', 'Phone', 'Type de compte', 'Autres'],
      usersToListen: ['Autres'],
      tempUpdateUserRoleId: null,
      tempUpdateUserId: null,
      openUpdateUserRole: false,
      exitOrgUpdateModal3: null,
      exitOrgMemberModal: null,

      // ??
      callbackPayload: null,
      exitRoleModalDel: null,
      exitOrgUpdateModal: null,

      new_member_email: '',
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },
    getRoles () {
      this.waitingAPIResponse = true
      getAPI.get('roles/')
          .then(response => {
            if(response.status == 200) {
              let roles = response.data.results;
              this.roles = []
              for(let i=0; i<roles.length; i++) {
                let row = roles[i]
                let name = `<a style="color: #000; text-transform: uppercase; text-decoration: none; font-weight: bold;" href="#"><i class="fa fa-cube"></i> ${row.name}</a>`
                let update = this.formatDate(row.updated_at)
                let btn = `<div class="d-flex align-items-center">
                                ( ${row.permissions.length} )  <button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-primary btn-action">EDITER PERMISSIONS</button>
                                <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-trash"></i></button>
                           </div>`
                this.roles.push({'id': row.id,'n°': (i+1), 'Intitulé': name, 'Dernière modification':update, 'Autres': btn, name: row.name})
              }
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    getCallToAction1 (payload) {
      if(payload.action_type == 'delete') {
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      }
      if(payload.action_type == 'view') {
        this.updateData(payload.id);
      }
    },
    confirmDeletion1 () {
      if(this.name_check1 != this.tempDeleteName) {
        this.callbackPayload = 'Oups ! Champ incorrecte.' + '::-1::' + Date.now();
      }
      else{
        getAPI.delete(`roles/${this.tempDeleteID}`)
            .then(response => {
              if(response.status == 204) {
                this.callbackPayload = 'Rôle supprimé avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.getRoles()
                }, 1500)
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitRoleModalDel = Date.now()
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
      }
    },
    getPermissions () {
      this.waitingAPIResponse = true
      getAPI.get('permissions/all')
          .then(response => {
            if(response.status == 200) {
              let permissions = response.data.data;
              this.permissions = []
              for(let i=0; i<permissions.length; i++) {
                let row = permissions[i]
                let name = `<a style="color: #000; text-transform: uppercase; text-decoration: none; font-weight: bold;" href="#"><i class="fa fa-key"></i> ${row.name}</a>`
                let description = `${row.description}`
                let update = this.formatDate(row.updated_at)
                this.permissions.push({'n°': (i+1), 'Intitulé': name, 'Dernière modification':update, 'Description': description, name: row.name, id: row.id})
              }
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    updateData (id) {
      this.tempUpdateRoleId = id
      this.waitingAPIResponse2 = true
      this.openUpdatePerm = true
      getAPI.get(`roles/${id}`)
          .then(response => {
            if(response.status == 200) {
              let role = response.data;
              this.oldPermission = role.permissions;
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse2 = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse2 = false
          })
    },
    confirmUpdate () {
        getAPI.patch(`roles/${this.tempUpdateRoleId}/`, {
          permissions: this.oldPermission,
        })
        .then(response => {
          if(response.status == 200) {
            let msg = 'Role MAJ avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitOrgUpdateModal = Date.now()
            this.getRoles()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal = Date.now()
        })
        .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitOrgUpdateModal = Date.now()
            })
    },
    addNewRole () {
      if(this.role_name != '') {
        const formData = new FormData();
        formData.append('name', this.role_name);

        getAPI.post('roles/', {
          permissions: this.newPermission,
          name: this.role_name
        })
        .then(response => {
          if(response.status == 201) {
            this.getRoles()
            let msg = 'Role ajouté avec succès!'
            this.alertString = `${msg}|alert-yes|7000::`+Date.now()
            this.exitRoleModal = Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitRoleModal = Date.now()
        })
        .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitRoleModal = Date.now()
            })
      }
      else{
        let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitRoleModal = Date.now()
      }
    },
    getUser () {
      this.waitingAPIResponse = true
      getAPI.get('accounts/admin/list')
        .then(response => {
          if(response.status == 200) {
            let users = response.data.results;
            this.users = []
            for(let i=0; i<users.length; i++) {
              let row = users[i]
              let name = `<a style="color: #000; text-transform: uppercase; text-decoration: none; font-weight: bold;" href="#"><i class="fa fa-user"></i> ${row.firstname} ${row.lastname}</a>`
              let btn = `<div class="d-flex align-items-center"><button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-primary btn-action">ROLES</button>`
              btn += (row.is_active) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action"><i class="fa fa-eye"></i></button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-eye-slash"></i></button>`;
              btn += '</div>'
              this.users.push({'n°': (i+1), 'Nom & Prénoms': name, 'Email':row.email, 'Phone':row.phone, 'Autres': btn, name: i, id: row.id, 'Type de compte': row.type_account.toUpperCase()})
            }
            console.log(this.users)
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    getCallToAction3 (payload) {
      if(payload.action_type == 'activate'||payload.action_type == 'deactivate') {
        if(payload.name == 0) {
          let msg = 'Que serait une maison sans son maitre? L\'utilisateur Noyau ne peut être désactiver.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
          return;
        }
        getAPI.put(`accounts/users/change-statut/${payload.id}/`, {
          is_active: (payload.action_type == 'activate') ? true : false
        }).then(response => {
          if(response.status == 200) {
            let msg = 'Utilisateur MAJ avec succès.'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.getUser()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        }).catch(() => {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        })
      }
      if(payload.action_type == 'view') {
        this.updateData3(payload);
      }
    },
    updateData3: function (payload) {
      if(payload.name == 0) {
        let msg = 'L\'utilisateur Noyau ne peut subir d\'altération de role.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        return;
      }
      let id = payload.id;
      this.tempUpdateUserId = id
      this.tempUpdateUserRoleId = id
      this.waitingAPIResponse3 = true
      this.openUpdateUserRole = true
      getAPI.get(`accounts/user/${id}/`)
        .then(response => {
          if (response.status == 200) {
            let user = response.data.data;
            this.tempUpdateUserRoleId = user.role;
          } else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::` + Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse3 = false
          }, 2000);
        })
        .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::` + Date.now()
            } else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::` + Date.now()
            } else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::` + Date.now()
            }
            this.waitingAPIResponse3 = false
          })
    },
    confirmUpdate3 () {
      getAPI.put(`accounts/users/change-role/${this.tempUpdateRoleId}/`, {
        role: this.tempUpdateUserRoleId,
      }).then(response => {
          if(response.status == 200) {
            let msg = 'Role MAJ avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitOrgUpdateModal3 = Date.now()
            this.getUser()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal3 = Date.now()
        })
        .catch(error => {
          if (error.response) {
            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgUpdateModal3 = Date.now()
        })
    },
    addNewMember () {
      if(this.new_member_email != '') {
        getAPI.post('accounts/admin/register', {'email': this.new_member_email})
          .then(response => {
            if(response.status == 201) {
              this.getUser()
              let msg = 'Utilisateur invité avec succès!'
              this.alertString = `${msg}|alert-yes|7000::`+Date.now()
              this.exitOrgMemberModal = Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgMemberModal = Date.now()
          })
          .catch(error => {
            if (error.response) {
              console.log(error.response.data);
              let firstError = Object.values(error.response.data.data)[0][0].replace(' en ', " ")
              this.alertString = `${firstError}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgMemberModal = Date.now()
          })
      }
      else{
        let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitRoleModal = Date.now()
      }
    }
  },
  mounted() {
    this.getRoles(),
    this.getPermissions()
    this.getUser()
  }
}
</script>

<style scoped>
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-certif-state-line:hover{
    /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-progress-status{
  min-width: 7em;
}
.item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 0.25em;
}

/* TAB STYLE */
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 10px;*/
  /*border-bottom: 1px solid #ccc;*/
}

.tab-header ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-header ul li {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.tab-header ul li.active {
  background-color: #333;
  color: #fff;
}

.tab-body {
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 0 .5em .5em .5em;
  min-height: 10em;
  /*background-color: #fff;*/
}
</style>

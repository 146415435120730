<template>
  <div class="cfa-root">
    <c-f-a-nav>
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title"><span class="cfa-primary">MODULE 1 :</span> SECURITÉ DU POST DE TRAVAIL</h1>
          <router-link :to="{name: 'ModulePreview', params: {id: '6de8547856985412254dj44522'}}">
            <button class="btn cfa-btn-primary"><i class="fa fa-chevron-left">&nbsp;&nbsp;</i>RETOUR</button>
          </router-link>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <div class="courseIframe d-flex position-relative">
            <iframe v-show="!waitingHttpResponse" class="col-12" :src="url" title="CyberForceAcademy - Courses Interface"></iframe>
            <pre-loader v-show="waitingHttpResponse" />
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import PreLoader from '@/components/shared/PreLoader.vue'

export default {
  name: 'WebContainerView',
  props: {
    url: {
      type: String,
      default: 'https://iseazy.com/dl/f8658e755a02432e9235567ea8c68b4e#/slide/IYC8pSktB'
    }
  },
  components: {
    CFAFooter,
    CFANav,
    PreLoader
  },
  data () {
    return {
      waitingHttpResponse: true,
    }
  },
  mounted() {
    document.querySelector('iframe').onload = () => {
      this.waitingHttpResponse = false
    }
    // document.getElementById('cfa-menu-swiper').click()
    // document.getElementById('cfa-menu-swiper').click()
    document.getElementById('cfa-nav-bottom-swiper').click()
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.courseIframe iframe{
    padding: 0;
    overflow-y: hidden;
  }
  .courseIframe{
    width: 100%;
    min-height: 100vh;
    /* margin-top: 5em; */
  }
  .iframeAction{
    width: 100%;
    height: 5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 1000;
  }
  .bg-summary{
    background-color: #f3f3f3;
  }
</style>
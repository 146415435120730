import axios from 'axios'

//Constant
// const BaseURL       = (process.env.NODE_ENV == 'development') ? "http://localhost:3100/api/" : "https://back-dev-cfa.cyberspector.xyz/api/"
// const BaseURL       = (process.env.NODE_ENV === 'development') ? "https://api-dev-cfa.cyberspector.xyz/api/" : "https://api-dev-cfa.cyberspector.xyz/api/"

const BaseURL = process.env.VUE_APP_BASE_URL

//const BaseURL =
  //process.env.NODE_ENV === "development"
    //? "https://api-dev-cfa.cyberspector.xyz/api/"
    //: "https://api-dev-cfa.cyberspector.xyz/api/";

//const BaseChatURL   = null

//Guest
const guestAPI = axios.create({
    baseURL: BaseURL,
})

//Authorise
const getAPI = axios.create({
    baseURL: BaseURL,
    headers: sessionStorage.getItem('token') != undefined ? {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`} : {}
})

// Interceptor for request
getAPI.interceptors.request.use(
    function (config) {
        let token = sessionStorage.getItem('token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Function to handle token expiration prompt
function promptTokenExpire() {
    let acceptLogin = 0;
    while(!acceptLogin) {
        acceptLogin = confirm("Your session has expired. Please login to continue.")
    }
    sessionStorage.clear();
    let a = document.createElement('a')
    a.setAttribute('href', "/")
    a.click()
    a.remove()
}

// Interceptor for response
getAPI.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log(error)
        if(error.response.status == 413) {
            alert(`Oops! Uploaded File Too Large - Request Aborted.`)
        }
        if(error.response.data.code == "token_not_valid" || error.response.data.detail == "Given token not valid for any token type") {
            getAPI.post('accounts/login/refresh/', {
                "refresh": sessionStorage.getItem('refresh_token')
            })
            .then(response => {
                if(response.status == 200) {
                    const token = response.data.access
                    sessionStorage.setItem('token', token)
                }
                else {
                    promptTokenExpire();
                }
            })
            .catch(() => {
                promptTokenExpire();
            })
        }
        else {
            return Promise.reject(error);
        }
    }
);

export { getAPI, guestAPI, BaseURL }

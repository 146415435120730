<template>
  <div class="select-container w-100">
    <div class="select-header w-100" @click="toggleOptions">
      <div class="select-selected">{{ selectedOption }}</div>
      <div class="select-caret"></div>
    </div>
    <div v-if="showOptions" class="select-options">
      <div v-for="option in options" :key="option.id" class="select-option" @click="selectOption(option)">
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedOption: null,
      showOptions: false
    }
  },
  mounted() {
    if (this.value) {
      this.selectedOption = this.value.label
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions
    },
    selectOption(option) {
      this.selectedOption = option.label
      this.showOptions = false
      this.$emit('input', option)
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.selectedOption = newValue.label
      } else {
        this.selectedOption = null
      }
    }
  }
}
</script>

<style scoped>
.select-selected{
  min-height: 1.5em;
}
.select-container {
  position: relative;
  display: inline-block;
}

.select-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  border: 3px solid #ced4da;
}

.select-caret {
  margin-left: 6px;
  border-top: 6px solid #666;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: auto;
  max-height: 200px;
}

.select-option {
  padding: 8px 12px;
  cursor: pointer;
}

.select-option:hover {
  background-color: #f0f0f0;
}
</style>

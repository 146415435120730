<template>
  <div class="cfa-customized-select position-relative">
    <button @click="showDropDown" :class="'btn cfa-select-btn '+cssClass" :title="label" :style="'height: 2.9em; ' + cssStyle">
      <span class="mText mLabel" v-html="this.label"></span>
      <span class="mText" style="color: #fff;">
        <i v-if="showArrow" :class="arrow"></i>&nbsp;
      </span>
    </button>
    <div :id="uid" class="cfa-dropdown-list d-flex flex-column d-none">
      <a v-for="element, i in dropDownLabel" :href="'#'+dropDownLink[i]" v-bind:key="i">
        <button @click="emitData(dropDownLink[i])" :style="dropDownStyle" class="btn linkStyle">
          <span v-html="element"></span>
          <!-- {{ element }} -->
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectButton',
  props: {
    uid: {
      type: String,
      default: 'cfa-select-button-01'
    },
    dropDownLabel: {
      type: Array,
      default: () => ['Link1', 'Link2']
    },
    dropDownLink: {
      type: Array,
      default: () => ['01', '02']
    },
    dropDownStyle: {
      type: Array,
      default: () => ['background-color: red !important;', 'background-color: #ddd !important;']
    },
    label: {
      type: String,
      default: 'BUTTON'
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    cssStyle: {
      type: String,
      default: ""
    },
    cssClass: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      arrow: 'fa fa-plus'
    }
  },
  methods: {
    showDropDown () {
      if(document.getElementById(this.uid).classList.contains('d-none')) {
        this.arrow =  'fa fa-chevron-down'
        document.getElementById(this.uid).classList.remove('d-none');
      }
      else {
        this.arrow =  'fa fa-plus'
        document.getElementById(this.uid).classList.add('d-none');
      }
    },
    emitData (data) {
      this.$emit('action', data)
    }
  },
  mounted () {
    // alert(document.getElementById(this.uid).classList.contains('d-none'))
    // document.body.addEventListener('click', (e) => {
    //   console.log(e.target)
    //   document.getElementById(this.uid).classList.add('d-none');
    // });
  }
}
</script>

<style scope>
.mLabel{
  color: #fff !important;
  font-size: .9em !important;
}
.cfa-select-btn{
  border: 1px solid #eceff5 !important;
  border-radius: .5em !important;
  background-color: #fff !important;
}
.cfa-select-btn span i{
  font-size: .7em;
  position: relative;
  bottom: .2em;
  left: .4em;
}
.cfa-dropdown-list{
  background-color: #fff;
  padding: 0.2em;
  border: 1px solid #eceff5 !important;
  margin-top: .2em;
  border-radius: .5em !important;
  min-width: 13em;
  position: absolute;
  right: 0;
  padding-bottom: 0.4em;
}
.cfa-dropdown-list button{
  width: calc(100% - .4em);
  margin-top: .4em;
  margin-left: .2em;
  margin-right: .2em;
}
.linkStyle:hover{
  color: #06bbc4 !important;
}
/*.cfa-dropdown-list:last-of-type button{
  margin-bottom: 0em;
}*/
</style>

<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div v-if="waitingAPIResponse == true" class="w-100 pt-5 text-start mt-2 mb-3">
          <h1 class="cfa-section-title" style="color:black;"><span class="cfa-primary text-uppercase">{{ this.$route.params.module }} :</span> {{ moduleData.title }}</h1>
        </div>
        <div v-else></div>
      </template>
      <template v-slot:content>
       <div v-if="waitingAPIResponse == true" class="cfa-page">
          <div v-show="moduleData != null" class="d-flex flex-column flex-md-row mt-3 flex-1">
            <div class="col-12 col-md-12 pe-0 pe-md-2">
              <div class="cfa-welcome ml-0">
                <div class="cfa-welcome-video">
                  <c-f-a-video :placeholder="this.moduleData.cover_image" :video-link="this.moduleData.micro_learning_url" :showLabel="false" maxHeight="35"></c-f-a-video>
                </div>
                <div class="py-3 d-flex justify-content-between">
                  <button class="btn btn-discret cfa-btn-primary">
                    <img width="10" src="../../assets/Icons/All_Icon/Vector-7.png" alt="cfa assets"/>
                    {{ $route.params.module }}
                  </button>
                </div>
                <div class="pb-3 pt-4">
                  <h1 class="cfa-section-title fs-1/2">{{ moduleData.title }}</h1>
                </div>
                <div class="py-3 d-flex">
                  <div class=" d-flex align-items-center">
                    <img width="13" src="../../assets/Icons/All_Icon/Vector-24.png" alt="cfa assets image"/>
                    <span class="ms-2">Durée: <b class="cfa-primary">timeToSeconds({{ moduleData.duration }})</b></span>
                  </div>
                  <div class="ms-2 d-flex align-items-center">
                    <img width="13" src="../../assets/Icons/All_Icon/Vector-9.png" alt="cfa assets image"/>
                    <span class="ms-2">Language: <b class="cfa-primary">FRANÇAIS</b></span>
                  </div>
                </div>
                <br>
                <div class="py-3">
                  <h1 class="cfa-section-title fs-1/2">DESCRIPTION</h1>
                  <hr>
                  <p class="w-100">Aperçu du Micro Learning</p>
                  <p class="w-100" style="color: rgb(38, 36, 36); font-weight:bold;">
                    {{
                        moduleData.description
                    }}
                  </p>
                  <br>
                  <p class="d-flex align-items-center">
                    <img width="10" src="../../assets/Icons/All_Icon/Vector-10.png" alt="cfa assets"/>
                    &nbsp;
                    <strong>
                      Micro learning URL:
                      <span >{{moduleData.micro_learning_url}}&nbsp;</span>
<!--                      Mot de passe, Couriel, Virus-->
                    </strong>
                  </p>
                  <br>
                  
                  <br>
                  <hr>
                </div>
                <div class="py-3">
                  <h1 class="cfa-section-title fs-1/2">Message</h1>
                  <div class="mt-4 cfa-welcome cfa-resources-course p-4">
                    <!-- <h3>Semaine 01</h3> -->
                    <div>
                      <div class="row">

                       <div class="col-md-3">
                        <h1 class="cfa-section-title fs-1/2"> SMS Message</h1>
                        <div  class="d-flex justify-content-left align-items-center ">
                          
                          <p>{{moduleData.sms_message}}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h1 class="cfa-section-title fs-1/2">IM Message</h1>
                        <div  class="d-flex justify-content-left align-items-center ">
                          <p>{{moduleData.im_message}}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h1 class="cfa-section-title fs-1/2">Bottom Message</h1>
                        <div  class="d-flex justify-content-left align-items-center ">
                          <p>{{moduleData.bottom_email_message}}</p>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <h1 class="cfa-section-title fs-1/2">Top Message</h1>
                        <div  class="d-flex justify-content-left align-items-center ">
                          <p>{{moduleData.top_email_message}}</p>
                        </div>
                      </div>
             
                      </div>
                      <div class="py-3 mt-5">
                      
        <div class="card">
            

            <DataTable ref="dt" :value="Allcampaign" v-model:selection="selectedProducts" dataKey="id"
                :paginator="true" :rows="10" :filters="filters"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} campaign">
                <template #header>
                    <div class="flex flex-wrap gap-2 align-items-center justify-content-between">
                        <h4 class="m-0">Micro learning campaign</h4>
						
					</div>
                </template>

                <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                <Column field="name" header="Nom" sortable style="min-width:12rem"></Column>
                <Column field="campaign_type" header="Campaign Type" sortable style="min-width:16rem"></Column>
                
                <Column field="created_at" header="Date création" sortable style="min-width:10rem"></Column>
                
                <Column field="status" header="Statut" sortable style="min-width:12rem">
                  
                    
                </Column>
                <Column :exportable="false" style="min-width:8rem,min-height:9rem" header="Actions">
                    <template #body="slotProps">
                        <!-- <Button icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="callUpdatecampaignForm(slotProps.data)" ><i class="fa  fa-pencil"></i></Button> -->
                        <!-- <Button v-if="slotProps.data.is_validated_by_admin ==false" icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="callUpdatecampaignForm(slotProps.data)" ><i class="fa  fa-pencil"></i></Button>
                         <Button v-else disabled icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="callUpdatecampaignForm(slotProps.data)" ><i class="fa  fa-pencil">validated</i></Button> -->
                         <Button v-if="slotProps.data.is_validated_by_admin ==false" icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="callUpdatecampaignForm(slotProps.data)" ><i class="fa  fa-pencil">Edit</i></Button>
                         <Button v-else disabled icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="callUpdatecampaignForm(slotProps.data)" ><i class="fa  fa-pencil">Edit</i></Button>
                         <Button v-if="slotProps.data.campaign_type == 'sms' " icon="fa fa-trash" :disabled="slotProps.data.status == 'In Progress' || slotProps.data.status == 'success'" outlined rounded severity="danger" class="ms-2 btn btn-success" @click="callUpdatecampaignForms(slotProps.data)" ><i class="fa fa-check">Validate</i></Button>
                         <Button v-if="slotProps.data.campaign_type == 'email' " icon="fa fa-trash" :disabled="slotProps.data.status == 'In Progress' || slotProps.data.status == 'success'" outlined rounded severity="danger" class="ms-2 btn btn-success" @click="callUpdatecampaignFormss(slotProps.data)" ><i class="fa fa-check">Validate</i></Button> 
                    </template>
                </Column>
            </DataTable>
        
          </div>
          </div>
           <scalable-modal  @callback="wizardUpdatedss" :break="exitOrgUpdateModal3" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-creates" @close="newDomainForm3=false" :open="newDomainForm3"  :advancedControl="true" validationButtonText="Nouveau email campaign" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                       
                        
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>url shortener</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedurlshortener" >
  <option selected disabled value="">Sélectionnez url shortener</option>
  <option v-for="declare in declarationData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                        
                    </template>
                </scalable-modal>
          <scalable-modal :disable-control="true" @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-phish-campaign-update" @close="newcampaignUpdate=false" :open="newcampaignUpdate"  :advancedControl="true" validationButtonText="Nouvelle sms campagne" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
            <template v-slot:content>
              <!--navigable-tabs | removed from the wizard-->
                <Wizard
                    squared-tabs
                    card-background
                    
                    scrollable-tabs
                    :nextButton="nextButtonOptionss"
                    :backButton="prevButtonOptionss"
                    :doneButton="doneButtonOptions"
                    :custom-tabs="[
                        {
                          title: 'Initialisation',
                        },
                        {
                          title: 'Choix de la cible',
                        },
                        {
                          title: 'Groupe',
                        },
                        {
                          title: 'Plannification',
                        },
                        
                    ]"
                    :beforeChange="onTabBeforeChange"
                    @change="onChangeCurrentTab"
                    @complete:wizard="wizardUpdated"
                >
                    <transition-group name="slide-fade--">
                      <div v-if="currentTabIndex === 0" class="stepper-body">
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Choisissez un nom unique pour votre campagne" v-model="campaignByid.name">
                                     
                                  </div>
                              </div>
                          </div>
                         
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Titre</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="micro_learning_id">
                                          <option  selected disabled>Selectionner un microlearning cible pour la campagne</option>
                                          <option v-for="group in microlearning" :value="group.id" v-bind:key="group">{{ group.title }}</option>
                                      </select>
                                  </div>
                                  
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Type</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="campaignByid.campaign_type" id="selectType">
                                          <option value="default" selected disabled>Selectionner le type de campagne</option>
                                          <option value="sms">SMS</option>
                                          <option value="email">EMAIL</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          
                      </div>
                      <div v-else-if="currentTabIndex === 1" class="stepper-body">
                        <div v-if="campaignByid.campaign_type == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur du SMS" v-model="campaignByid.sms_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Nom de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le nom de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_name">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'email'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur de l'e-mail</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisissez la signature de l'expéditeur de l'e-mail" v-model="campaignByid.email_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div v-if="campaignByid.campaign_type == 'sms'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Signature de l'expéditeur du SMS</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Saisir la signature de l'expéditeur du SMS" v-model="campaignByid.sms_send_signature">
                                  </div>
                              </div>
                          </div>
                          <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Campaign Target</strong></p>
                                  <div class="d-flex flex-row">
                                       <select class="form-control" v-model="newCampaignTarget" id="selectType">
                                          <option  selected disabled>Selectionner le target de campagne</option>
                                          <option value="GROUP">Group</option>
                                          <option value="SERVICE">Service</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div v-else-if="currentTabIndex === 2" class="stepper-body">
                          <div v-if="newCampaignTarget ==  'GROUP'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Groupes cibles</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.group">
                                          <option :value="null" selected disabled>Selectionner un groupe cible pour la campagne</option>
                                          <option v-for="group in groupAllData" :value="group.id" v-bind:key="group">{{ group.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div v-if="newCampaignTarget ==  'SERVICE'" class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Service</strong></p>
                                  <div class="d-flex flex-row">
                                      <select class="form-control" v-model="campaignByid.service">
                                          <option :value="null" selected disabled>Selectionner un service cible pour la campagne</option>
                                          <option v-for="service in serviceAllData" :value="service.id" v-bind:key="service">{{ service.name }}</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div v-else-if="currentTabIndex === 3" class="stepper-body">
                          <div class="row w-100 m-inherit mt-2">
                              <div class="form-group py-2 px-0">
                                  <p class="mb-2"><strong>Date de planification <span style="color: red; opacity: 0.5; font-size: 0.9em;">(un délai d'attente de 03 heures sera ajouté à l'heure de lancement choisie)</span></strong></p>
                                  <div class="d-flex flex-row">
                                      <VueDatePicker placeholder="Choisir la date de planification" v-model="planification_date" locale="fr" cancelText="Annuler" selectText="Choisir" :title="'DEFINIR UNE DATE'" :minDate="minDate"></VueDatePicker>
                                  </div>
                              </div>
                          </div>
                           <div class="row w-100 m-inherit">
                              <div class="form-group py-2 px-0 ">
                                  <p class="mb-2"><strong>Message par défaut</strong></p>
                                  <div class="d-flex flex-row">
                                    <input class="form-control" type="text" placeholder="Entrez le message par défaut" v-model="campaignByid.default_message">
                                     
                                  </div>
                              </div>
                           </div>
  
                      </div>
                      
                    </transition-group>
                </Wizard>
            </template>
          </scalable-modal>
         
                <scalable-modal @callback="wizardUpdateds" :break="exitOrgUpdateModal2" label="CRÉER UN PLAN" uid="cfa-modal-phish-template-create" @close="newDomainForm2=false" :open="newDomainForm2"  :advancedControl="true" validationButtonText="Nouveau campaign" exitButtonText="Annuler" displayMode="flex" :style="'width: 60em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
                    <template v-slot:content>
                        <!-- blo1 -->
                        
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>url shortener</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedurlshortener" >
  <option selected disabled value="">Sélectionnez url shortener</option>
  <option v-for="declare in declarationData" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="form-group py-2 pe-0 pe-md-2 ">
                                <p class="mb-2"><strong>sms provider</strong></p>
                                <div class="d-flex flex-row">
                                    <select class="form-control" v-model="selectedsmsprovider" >
  <option selected disabled value="">Sélectionnez sms provider</option>
  <option v-for="declare in sms_provider" :value="declare.id" v-bind:key="declare.name">{{declare.name}}</option>
</select>
                                </div>
                            </div>
                        </div>
                        
                    </template>
                </scalable-modal>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        
          <div v-show="moduleData == null">
            <div class="d-flex flex-column">
              <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
              <span class="mt-3 fs-5 fw-bold">Oups ! Rien pour le moment.</span>
            </div>
          </div>
      </div>
        <div v-else class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import CFAVideo from '@/components/shared/video/CFAVideo.vue'
// import PreLoader from '@/components/shared/PreLoader.vue'
import { getAPI } from '@/axios-api.js'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ScalableModal from "@/components/shared/modal/ScalableModal.vue";
  import 'form-wizard-vue3/dist/form-wizard-vue3.css'
  import Wizard from 'form-wizard-vue3'
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  //  import { addHours } from 'date-fns';

import { FilterMatchMode } from 'primevue/api';
// import Table from "@/components/shared/table/Table.vue";

export default {
  name: 'DashboardView',
  components: {
    ScalableModal,
  
         
    CFANav,
    Wizard,
    VueDatePicker,
    CFAFooter,
    
    CFAVideo,
    DataTable,
    Column,
    
    // Table,
    // PreLoader
    // DoughnutChart,
    // CourseCard
  },
  data () {
    return {
      /*https://iseazy.com/dl/f8658e755a02432e9235567ea8c68b4e#/slide/IYC8pSktB*/
      url: '',
      campaignsAll: [],
      selectedProducts:'',
       filters: {},
       serviceAllData:[],
       groupAll: [],
       exitOrgUpdateModal3:null,
       newDomainForm2:false,
       newDomainForm3:false,
       newcampaignUpdate:false,
       declarationData:[],
       sms_provider:[],
       selectedurlshortener:'9462e3bd-3c5f-4803-8614-3fa7a50de04d',
       selectedsmsprovider:'a83fd831-f368-4484-b20c-f0a949206b6a',
      Allcampaign:[],
      exitOrgUpdateModal2:null,
      exitOrgUpdateModal:null,
      micro_learning_id:'',
      tempDeleteID:'',
      newCampaignType:'',
      campaignsAllOk: [],
      groupAllData:[],
      microlearning:[],
      currentTabIndex: 0,
      campaignsAllColumns: ['n°', 'Nom', 'Campaign Type', 'Date création', 'Actions'],
      campaignsAllToListen: ['Actions'],
      waitingHttpResponse: false,
      alertString: '',
      newCampaignTarget:'',
      campaignByid:[],
      waitingAPIResponse: false,
      planification_date:'',
      moduleData: null,
      resourceData: [],
      tags: [],
      linkedRessource: []
    }
  },
  methods: {
    timeToSeconds(timeString) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Convert hours, minutes, and seconds to total seconds
    const second = (hours * 3600) + (minutes * 60) + seconds;

    return second;
},
    callValidateCampaign(data){
     console.log(data);
    },
    callUpdatecampaignForm(data){
     this.newcampaignUpdate=true;
     this.getCallToActionForCampaigns(data);
     console.log(data);
    },
    callUpdatecampaignForms(data){
      this.getCallToActionForCampaignss(data);
     
                
                  this.newDomainForm2 = true;
                    
      //this.newDomainForm2=true;
     //this.newcampaignUpdate=true;
    //  this.getCallToActionForCampaignss(data);
     //this.wizardUpdateds();
     console.log("i",data);
    },
    callUpdatecampaignFormss(data){
      this.getCallToActionForCampaignss(data);
     
       
              this.newDomainForm3 = true;
              
                
      //this.newDomainForm2=true;
     //this.newcampaignUpdate=true;
     this.getCallToActionForCampaignss(data);
     //this.wizardUpdateds();
     console.log("i",data);
    },
    prevTemplate() {
              if (this.currentTemplateIndex > 0) {
                  this.currentTemplateIndex--;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          nextTemplate() {
              if (this.currentTemplateIndex < this.templateData1 - 1) {
                  this.currentTemplateIndex++;
                  this.currentTemplate = this.templateData[this.currentTemplateIndex];
              }
          },
          prevLanding() {
              if (this.currentTemplateIndex1 > 0) {
                  this.currentTemplateIndex1--;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
          nextLanding() {
              if (this.currentTemplateIndex1 < this.templateData1.length - 1) {
                  this.currentTemplateIndex1++;
                  this.currentTemplate1 = this.templateData1[this.currentTemplateIndex1];
              }
          },
          onChangeCurrentTab(index, oldIndex) {
            console.log(index, oldIndex);
            this.currentTabIndex = index;
          },
          onTabBeforeChange() {
            if (this.currentTabIndex === 0) {
                console.log('First Tab');
            }
            console.log('All Tabs');
          },
          async getAllDeclaration() {
            this.waitingAPITemplate = true
            getAPI.get(`smshing/url_shortener/`)
                .then(response => {
                    if(response.status === 200) {
                        this.declarationData = response.data.data;
                        
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        async getAllSmsProvider() {
            this.waitingAPITemplate = true
            getAPI.get(`smshing/sms_provider/`)
                .then(response => {
                    if(response.status === 200) {
                        this.sms_provider = response.data.data;
                        
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    setTimeout(() => {
                        this.waitingAPITemplate = false
                    }, 2000);
                })
                .catch(error => {
                    if (error.response) {
                        this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                        let msg = 'The request was made but no response was received. Please check your network.'
                        this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                        let msg = 'Oups ! something went wrong.'
                        this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPITemplate = false
                })
        },
        wizardUpdatedss() {
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                

              };
              console.log(micro_learning);
              getAPI.post(`micro_learning/validate_campaign`, {
                  //name: this.campaignByid.name,
                  campaign_id: this.tempDeleteID,
                  url_shortener_id: this.selectedurlshortener,
                  microlearning_id: this.$route.params.id,
                  test_email: "onesime.adefoulou@gmail.com",
                  is_validated_by_admin: true,
                  
                  
              })
                  .then(response => {
                      if(response.status == 201) {
                           this.newDomainForm3 = false;
                          let msg = 'Campagne validation avec succès.'
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                           this.exitOrgUpdateModal3 = Date.now();
                           this.$swal({
                             title: '',
                             text: 'Campagne validation avec succès. ',
                             type: 'success',
                              showCancelButton: false,
                             confirmButtonText: "D'accord !"
                            });
                            window.location.reload();
                          this.getAllGroup()
                          this.getAllCampaign()
                          
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal3 = Date.now();
                    
                      this.$swal({
                          title: '',
                          text: 'Oups ! something went wrong.',
                          type: 'warning',
                          showCancelButton: false,
                          confirmButtonText: "D'accord !"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                         let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal2 = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
          wizardUpdateds() {
              
             
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                

              };
              console.log(micro_learning);
              getAPI.post(`micro_learning/validate_campaign`, {
                  //name: this.campaignByid.name,
                  campaign_id: this.tempDeleteID,
                  microlearning_id: this.$route.params.id,
                  sms_provider_id: this.selectedsmsprovider,
                  url_shortener_id: this.selectedurlshortener, 
                  test_phone_number: '+250780958495',
                  is_validated_by_admin: true,
                  
                  
              })
                  .then(response => {
                      if(response.status == 201) {
                            this.exitOrgUpdateModal2 = Date.now();
                            this.newDomainForm2 = false;
                            let msg = 'Campagne validation avec succès.'
                            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                             this.$swal({
                             title: '',
                              text: 'Campagne validation avec succès.',
                              type: 'success',
                               showCancelButton: false,
                               confirmButtonText: "D'accord !"
                             });
                              window.location.reload();
                          this.getAllGroup()
                          this.getAllCampaign()
                          
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                     
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
          wizardUpdated() {
              
              const groups = [];
              const service = [];
            //   const serve=["93c1afcb-5838-4ae3-905c-80e6196f4204"];
            //   const group=["3421b42e-55f8-46fb-ae95-32c3a48bc19e"];
              service.push(this.newCampaignService)
              groups.push(this.newCampaignGroupe);
              console.log(this.campaignByid.planification_date);
              // let newCampaignDateStartF = new Date(this.planification_date);
              // newCampaignDateStartF = addHours(this.planification_date, 3);
              let micro_learning={};
               micro_learning={id:this.micro_learning_id,
                bottom_email_message:this.campaignByid.micro_learning.bottom_email_message,
                title:this.campaignByid.micro_learning.title,
                micro_learning_url:this.campaignByid.micro_learning.micro_learning_url,
                sms_message:this.campaignByid.micro_learning.sms_message,
                top_email_message:this.campaignByid.micro_learning.top_email_message,
                im_message:this.campaignByid.micro_learning.im_message,
                duration:this.campaignByid.micro_learning.duration,
                description:this.campaignByid.micro_learning.description,

              };
              console.log(micro_learning);
              getAPI.patch(`micro_learning/campaigns/${this.tempDeleteID}/`, {
                  //name: this.campaignByid.name,
                  micro_learning: micro_learning.id,
                  campaign_type: this.campaignByid.campaign_type,
                  sms_send_name: this.campaignByid.sms_send_name || null,
                  group: this.campaignByid.group || null,
                  email_send_name: this.campaignByid.email_send_name || null,
                  sms_send_signature: this.campaignByid.sms_send_signature || null,
                  email_send_signature: this.campaignByid.email_send_signature || null,
                  planification_date: this.planification_date,
                  default_message:this.campaignByid.default_message,
                  is_validated_by_admin: false,
                  service:this.campaignByid.service || null,
                  status:"available"
                  
              })
                  .then(response => {
                      if(response.status == 200) {
                          this.getAllGroup()
                          this.getAllCampaign()
                          let msg = 'Campagne mise à jour avec succès!'
                          this.campaignWaiting = response.data;
                          console.log("Camp: ", this.campaignWaiting)
                          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                      }
                      else {
                          
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                      this.$swal({
                          title: 'Campagne créée avec succès',
                          text: 'Campagne créée avec succès. En attente de validation.',
                          type: 'warning',
                          showCancelButton: false,
                          confirmButtonText: "D'accord !"
                      });
                  })
                  .catch(error => {
                      if (error.response) {
                          this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      this.exitOrgUpdateModal = Date.now();
                      this.newCampaignUpdate = false;
                  });
          
          
      },
      async getAllService () {
              this.waitingAPIResponse = true
              getAPI.get(`services`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.serviceAllData = response.data.data;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
          },
   async initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
    async getAllCampaign() {
    try {
        const response = await getAPI.get(`micro_learning/campaigns-by-micro-learning/${this.$route.params.id}/`);
        if (response.status == 200) {
            const groups = response.data.results;
            console.log('Response Data:', groups, Array.isArray(groups));

            // Reset Allcampaign
            this.Allcampaign = response.data.results;

            // Log the initial state of Allcampaign
            console.log('Initial Allcampaign:', this.Allcampaign);

            // Iterate over each group and push data into Allcampaign
            for (let i = 0; i < groups.length; i++) {
                const row = groups[i];
                const btn = `<div class="d-flex align-items-center">` +
                    `<button btn-id="${row.id}" btn-action="view" class="ms-2 btn cfa-btn-primary btn-action"><i class="fa fa-area-chart"></i></button>` +
                    `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>` +
                    `</div>`;

                const campaignItem = {
                    'n°': (i + 1),
                    'Nom': `<span class="selectable">${row.name}</span>`,
                    'Campaign Type': `<span class="selectable">${row.campaign_type}</span>`,
                    'Statut': `${row.status == "created" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">VALIDATION PENDING</span>` : (row.status == "canceled" ? `<span class="badge rounded-pill badge-danger cfa-bg-secondary mw-status">REJECTED</span>` : `<span class="badge rounded-pill badge-success cfa-bg-danger mw-status">IN PROGRESS</span>`)}`,
                    'Date création': this.convertDate(row.created_at, true),
                    'Actions': btn,
                    name: row.name,
                    id: row.id
                };

                this.Allcampaign.push(campaignItem);
            }

            // Log the final state of Allcampaign
            console.log('Final Allcampaign:', this.Allcampaign);
        } else {
            // this.handleErrorResponse();
        }
    } catch (error) {
        // this.handleErrorResponse(error);
    }
},
// handleErrorResponse(error) {
//     let errorMsg = 'Oups! Something went wrong.';
//     if (error) {
//         if (error.response) {
//             errorMsg = `Vous n'appartenez à aucune organisation`;
//         } else if (error.request) {
//             errorMsg = 'The request was made but no response was received. Please check your network.';
//         }
//     }
//     this.alertString = `${errorMsg}|alert-no|5000::${Date.now()}`;
// },

   
   
          
    async getModuleData() {
      try {
        this.waitingAPIResponse = true;
        const [response1] = await Promise.all([
          getAPI.get(`micro_learning/courses/${this.$route.params.id}/`),
        ]);
        if(response1.status == 200 ) {
          console.log(this.campaignsAll);
          this.moduleData = response1.data
          this.url = this.moduleData.link
          this.resourceData = response1.data.results
         
          // this.tags = response3.data.results;
          let modulesTagsId = Object.values(this.moduleData.tags)
          for(let i = 0; i<this.tags.length; i++) {
            // console.log(tags[i].id)
            if(modulesTagsId.includes(this.tags[i].id)) {
              // this.tags.push(this.tags[i].name);
              // console.log(this.tags);
            }
          }
          
          
          this.waitingAPIResponse = false
        }
        else{
          this.waitingAPIResponse = false
          this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
        }
      }
      catch (e) {
        console.log(e);
        // this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
      }
    },
    getCallToActionForCampaignss (payload) {
            // if(payload.action_type == 'delete') {
            //     this.openDeletionCampaignBox = true;
            //     this.tempDeleteID = payload.id;
            //     this.tempDeleteName = payload.name;
            
            // }
            
              //  this.newcampaignUpdate = true;
               this.tempDeleteID = payload.id;
               getAPI.get(`micro_learning/campaigns/${payload.id}`)
                  .then(response => {
                      if(response.status == 200) {
                          
                        this.campaignByid = response.data;
                         console.log(this.campaignByid.micro_learning.id);
                         this.micro_learning_id=this.campaignByid.micro_learning.id;
                         this.planification_date=this.campaignByid.planification_date
                      }
                      else {
                          // let msg = 'Oups ! something went wrong.'
                          // this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          // let msg = 'The request was made but no response was received. Please check your network.'
                          // this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          // let msg = 'Oups ! something went wrong.'
                          // this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
           
  },
    getCallToActionForCampaigns (payload) {
            // if(payload.action_type == 'delete') {
            //     this.openDeletionCampaignBox = true;
            //     this.tempDeleteID = payload.id;
            //     this.tempDeleteName = payload.name;
            
            // }
            
              //  this.newcampaignUpdate = true;
               this.tempDeleteID = payload.id;
               getAPI.get(`micro_learning/campaigns/${payload.id}`)
                  .then(response => {
                      if(response.status == 200) {
                        this.campaignByid = response.data;
                         console.log(this.campaignByid.micro_learning.id);
                         this.micro_learning_id=this.campaignByid.micro_learning.id;
                         this.planification_date=this.campaignByid.planification_date
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          let msg = 'The request was made but no response was received. Please check your network.'
                          this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          let msg = 'Oups ! something went wrong.'
                          this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
           
  },
  async getAllMicroLearning () {
                         this.waitingAPIResponse = true
              getAPI.get(`micro_learning/courses/`)
                  .then(response => {
                      if(response.status == 200) {
                          
                          this.microlearning = response.data.results;
                          
                          
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                      
                  })
          },
          async getAllGroup () {
              
              getAPI.get(`groupes`)
                  .then(response => {
                      if(response.status == 200) {
                          let groups = response.data.data;
                          this.groupAllData = response.data.data;
                          this.groupAll = []
                          for(let i=0; i<groups.length; i++) {
                              let row = groups[i];
  
                              let users_count = `
                                  <span class="badge rounded-pill badge-danger" style="background-color: var(--primary-color);">${row.users.length}</span>
                              `;
                              let btn = `<div class="d-flex align-items-center">`;
                              btn += `<button btn-id="${row.id}" btn-action="edit" class="ms-2 btn cfa-btn-light btn-action"><i class="fa fa-eye"></i></button>`;
                              btn += `<button btn-id="${row.id}" btn-action="delete" class="ms-2 btn btn-danger btn-action"><i class="fa fa-trash"></i></button>`;
                              btn += `</div>`;
  
                              this.groupAll.push({'n°': (i+1), 'Nom': `<span class="selectable" style="color: #132B4D">${row.name}</span>`, 'Utilisateurs':users_count, 'Denière modification':this.convertDate(row.updated_at), 'Actions': btn, name: row.name, id: row.id})
                          }
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                     
                  })
                  .catch(error => {
                      if (error.response) {
                          //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                      }
                      else if (error.request) {
                          //let msg = 'The request was made but no response was received. Please check your network.'
                          //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                      }
                      else {
                          //let msg = 'Oups ! something went wrong.'
                          //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                      }
                     
                  })
          },
          
}, 
 
computed: {
          
          nextButtonOptionss() {
              return (this.currentTabIndex === 33)
                  ? {
                      text: 'Créer la campagne',
                      icon: 'check',
                      hideIcon: true, // default false but selected for sample
                      hideText: false, // default false but selected for sample
                      disabled: false,
                  }
                  : ((this.currentTabIndex === 0 && (this.campaignByid.name == null || this.campaignByid.campaign_type === 'default' )) ?
                      {
                          disabled: true,
                          text: "Suivant",
                      }
                      : ((this.currentTabIndex === 1 && (this.newCampaignType == null)) ?
                          {
                              disabled: true,
                              text: "Suivant",
                          }
                          : ((this.currentTabIndex === 2 && (this.newCampaignTarget == null)) ?
                              {
                                  disabled: true,
                                  text: "Suivant",
                              }
                              : ((this.currentTabIndex === 3 && (this.newCampaignTarget == null)) ?
                                  {
                                      disabled: true,
                                      text: "Suivant",
                                  }
                                  : 
                                  {
                                          disabled: false,
                                          text: "Suivant",
                                  })
                              )
                          )
                      );
          },
          
          prevButtonOptionss() {
              return {
                  disabled: false,
                  text: "Précédent"
              };
          },
          doneButtonOptions() {
              return (this.newCampaignTarget == 'default') ? {
                  disabled: true,
                  text: "Terminé"
              }: {
                  disabled: false,
                  text: "Terminé"
              };
          },
      },
         
  async mounted() {
    await this.getAllDeclaration();
    await this.getAllSmsProvider();
    await this.getModuleData()
    await this.getAllCampaign()
    await this.initFilters()
    await this.getAllService ()
    await this.getAllMicroLearning ()
    await this.getAllGroup()
     
    // document.querySelector('iframe').onload = () => {
    //   this.waitingHttpResponse = false
    // }
    // document.getElementById('cfa-nav-bottom-swiper').click()
  },
  
}
</script>

<style scoped>
.courseIframe iframe{
  padding: 0;
  overflow-y: hidden;
}
.courseIframe{
  width: 100%;
  min-height: 100vh;
  /* margin-top: 5em; */
}.cfa-page{}
  .cfa-block{
      background-color: #fff;
      border-radius: 0.5em;
      padding: .5em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
      /* border: 1px solid transparent;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
  }
  
  /* TAB STYLE */
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 10px;*/
    /*border-bottom: 1px solid #ccc;*/
  }
  
  .tab-header ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .tab-header ul li {
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
  
  .tab-header ul li.active {
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-header ul li:hover{
    background-color: #333;
    color: #fff;
    border:1px solid #000;
  }
  
  .tab-body {
    /*padding: 10px;*/
    border: 1px solid #ccc;
    border-radius: 0 .5em .5em .5em;
    min-height: 10em;
  }
  .gFolder-container{
      background-color: var(--primary-light-color-2);
      border-radius: 0.5em;
      height: 4.3em;
      width: 4.6em;
      margin-right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .gBlock{
      align-items: center;
      justify-content: start;
      border-radius: 0.6em;
      padding-left: 2em;
      padding-right: 2em;
      height: 8em !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  .gBlock div:last-of-type p{
      margin: 0;
  }
  .gBlock div:last-of-type p:first-of-type{
      font-weight: 400;
      font-size: 2em;
  }
  .tab_font{
      font-size: 13px;
  }
  .user-row{
      background: #fff;
      padding-left: 1em;
      margin-bottom: 0.2em;
  }
  .row-user{
      transition: .2s all ease-in;
  }
  .row-user:hover{
      background: #f5f5f5;
      cursor: pointer;
  }
  
  .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  
  .left-button,
  .right-button {
      padding: 10px;
      cursor: pointer;
      background-color: lightgray;
  }
  
  .middle-div {
      flex: 1;
      margin: 0 10px;
  }
  
  .content {
      width: 100%;
      height: 461px;
      border: 1px solid #e0e1e8;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
  }
  .cfa-section-title{
      color: #fff;
  }
  .cfa-btn-primary{
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
  }
.iframeAction{
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.cfa-page{

}
.gFolder-container{
  background-color: #cef1f3;
  border-radius: 0.5em;
  height: 4.3em;
  width: 4.6em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gBlock{
  align-items: center;
  justify-content: start;
  border-left: 4px solid #25c2cb;
  border-radius: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  height: 8em !important;
}
.gBlock div:last-of-type p{
  margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
  font-weight: bold;
  font-size: 2em;
}
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  /* margin: .5em; */
}
.cfa-warning-deadline{
  border: 2px solid #42cbd0;
  background-color: #d6f6f3;
  color: #20c2c9;
  border-radius: .3em;
  -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
  box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
}
.cfa-alert-d{
  border-bottom: 1px solid #f3f3f3;
  transition: all ease-in .3s;
}
.cfa-alert-d:last-of-type{
  border: none;
}
.cfa-alert-d:hover{
  -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
  box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
}
.cfa-state-view{
  border-radius: .5em;
}
.cfa-resources-course{
  box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
}
.cfa-border-top-light{
  border-top: 1px solid #ddd;
}
.cfa-border-top-light:first-of-type{
  border-top: none;
}
.emptyBlock{
  width: 100%;
  height: 5em;
  background-color: #fddbdb;
}
</style>

<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" css_class="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3">
          <h1 class="cfa-section-title">RENDEZ-VOUS EXPERT</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page">
          <div class="cfa-page">
          <div class="d-flex flex-column flex-md-row mt-3">
            <Table :columns="rdvsColumns" :data="rdvs" :columns-action="rdvsToListen" classFilter="" @action="getCallToAction"></Table>
          </div>
        </div>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
    <!-- alert -->
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition>

    <!-- delete -->
    <scalable-modal @callback="confirmDeletion" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
      <template v-slot:content>
        <p>Etes vous sûr de vouloir supprimer cette image publicitaire? <span style="color: red;">Cette Action est irreversible</span></p>
        <div class="col-12 pl-0">
          <div class="form-group py-3 pe-0 d-flex align-items-center">
            <input id="deleteIt" type="checkbox" v-model="rdv_deletion_check">
            <label class="ms-2" for="deleteIt" ><strong>Je sais ce que je fais </strong></label>
          </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import Alert from '@/components/shared/alert/Alert.vue'
import {getAPI} from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";

// DataTable.use(Select);

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    Alert,
    Table,
  },
  data () {
    return {
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false,
      data: [],
      tableColumns: [
        { data: '#' },
        { data: 'date' },
        { data: 'user' },
        { data: 'subject' },
        { data: 'detail' },
        { data: 'actions' },
      ],
      tableOptions: { select: false },
      allAppointment: [],
      alertString: '',
      rdvs: [],
      rdvsColumns: ['n°', 'Date', 'Utilisateur', 'Sujet', 'Détail', 'Action'],
      rdvsToListen: ['Action'],
      openDeletionBox: false,
      tempDeleteID: '',
      tempDeleteName: '',
      exitOrgModalDel: null,
      rdv_deletion_check: null,
      callbackPayload: '',
    }
  },
  methods: {
    displayError(error, type='alert-ok', time=5000) {
      this.gAlertMessage = error
      this.gAlertType = type
      this.alertMe = true
      setTimeout(() => {
          this.alertMe = false
      }, time);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },
    prepareDataForVisualization (data) {
      this.rdvs = []
      for(let i=0; i<data.length; i++) {
        let row = data[i]
        let btn = `<div class="d-flex align-items-center">`
        btn += (row.is_valid == false) ? `
            <button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-gold btn-action">VALIDER/PROGRAMMER</button>
            <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-trash"></i></button>` :
            `<a href="${row.link}" target="_top"><button class="ms-2 btn btn-success btn-action"><i class="fa fa-meetup"></i>DEMARRER LA REUNION</button></a>`;
        btn += `</div>`;

        this.rdvs.push({
          'n°': (i+1),
          'Date': this.formatDate(row.date),
          'Utilisateur': row.user,
          'Sujet': row.subject,
          'Détail': row.detail,
          'Action': btn,
          name: row.name,
          id:row.id
        })
      }
    },
    getAllAppointment () {
      this.waitingAPIResponse = true;
      getAPI.get(`appointment`).then((response) => {
        if(response.status == 200) {
          this.allAppointment = response.data.results;
          this.prepareDataForVisualization(this.allAppointment);
          this.waitingAPIResponse = false
        }
        else {
          this.waitingAPIResponse = false
          this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
        }
      }).catch((error) => {
        this.waitingAPIResponse = false
        this.alertString = `${error.message||'Une erreur est survenu'}|alert-no|4000::`+Date.now()
      });
    },
    tryDeleteRDV () {
      // alert('ok');
    },
    clickDay(data) {
      console.log(data);
    },
    changeDate(data) {
      console.log(data);
    },
    clickToday(data) {
      console.log(data);
    },
    isPast(date) {
      const today = new Date()
      return date < today
    },
    getAvailableDate (date) {
      if(this.isPast(date)) {
        this.displayError(`Oups ! On ne peut pas remonter dans le passé. Du moins, pas encore 😂️. Veuillez sélectionner une date dans le futur pour prendre rendez-vous.`, 'alert-no', 8000)
      }
      else{
        this.dateRdv = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
        this.displayError(`La date du <b style="font-weight:bold;">${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}</b> est disponible. Completez le formulaire pour prendre rendez-vous cette date.`, 'alert-middle', 7000)
      }
    },
    getUnAvailableDate () {
      this.displayError('Oups ! Vous ne pouvez prendre rendez-vous ce jour. Un autre évemement y est prévu.', 'alert-no', 5000)
    },
    giveGuideLineForDate () {
      this.displayError('Référez-vous au calendrier précédent pour choisir une date derendz-vous !.', 'alert-middle', 5000)
    },
    getCallToAction (payload) {
      if(payload.action_type == 'delete') {
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      }
      if(payload.action_type == 'view') {
        getAPI.patch(`appointment/${payload.id}/`,{
          is_valid: true
        })
        .then(response => {
          if(response.status == 200) {
            this.getAllAppointment();
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        })
        .catch(error => {
          if (error.response) {
            this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
          }
          else if (error.request) {
            let msg = 'The request was made but no response was received. Please check your network.'
            this.alertString = `${msg}|alert-no|8000::`+Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        })
      }
    },
    confirmDeletion () {
      if(!this.rdv_deletion_check) {
        this.callbackPayload = 'Veuillez confirmer cette action.' + '::-1::' + Date.now();
      }
      else{
        getAPI.delete(`appointment/${this.tempDeleteID}`)
            .then(response => {
              if(response.status == 204) {
                this.callbackPayload = 'Rdv supprimé avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.getAllAppointment()
                }, 1500)
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitRoleModalDel = Date.now()
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
      }
    },
  },
  mounted() {
    this.getAllAppointment();
  },
  computed: {
  },
}
</script>

<style scoped>
  .cfa-page{

  }
  .cfa-welcome{
    background-color: #fff;
    border-radius: 0.5em;
    padding: 1em 1em;
      box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  }
  .form-control::placeholder {
    color: #999;
    opacity: 1;
  }
  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    background-clip: padding-box;
    font-weight: 400;
    /* transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; */
  }
  .form-control input{
    background-color: #f7f8fc;
    border-color: #e0e0ec;
  }
  input, select {
    outline: none !important;
  }
</style>

<template>
  <div class="cfa-root">
    <c-f-a-nav>
      <template v-slot:title>
        <!-- ... existing title ... -->
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <!-- row1 -->
          <div class="row d-flex flex-wrap">
            <!-- Existing blocks -->
            <!-- ... -->

            <!-- New blocks -->
            <div class="col-12 col-md-4 p-0">
      <div class="bg-white gBlock d-flex m-2 py-3">
        <div class="p-3 gFolder-container">
          <font-awesome-icon icon="users" />
        </div>
        <div>
          <p>{{ totalUserAccounts }}</p>
          <p>{{ $t('total_user_accounts') }}</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 p-0">
      <div class="bg-white gBlock d-flex m-2 py-3">
        <div class="p-3 gFolder-container">
          <font-awesome-icon icon="building" />
        </div>
        <div>
          <p>{{ totalOrganisationAccounts }}</p>
          <p>{{ $t('total_organisation_accounts') }}</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-4 p-0">
      <div class="bg-white gBlock d-flex m-2 py-3">
        <div class="p-3 gFolder-container">
          <font-awesome-icon icon="globe" />
        </div>
        <div>
          <p>{{ usersOnline }}</p>
          <p>{{ $t('users_online') }}</p>
        </div>
      </div>
    </div>
  </div>

          <!-- Charts row -->
          <div class="row mt-4">
      <div class="col-12 col-md-4">
        <div class="bg-white p-3 rounded">
          <h3>{{ $t('accounts_created_daily') }}</h3>
          <bar-chart v-if="dailyAccountsData" :chart-data="dailyAccountsData" :options="chartOptions"></bar-chart>
          <p v-else>Loading daily data...</p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="bg-white p-3 rounded">
          <h3>{{ $t('accounts_created_weekly') }}</h3>
          <bar-chart v-if="weeklyAccountsData" :chart-data="weeklyAccountsData" :options="chartOptions"></bar-chart>
          <p v-else>Loading weekly data...</p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="bg-white p-3 rounded">
          <h3>{{ $t('accounts_created_monthly') }}</h3>
          <bar-chart v-if="monthlyAccountsData" :chart-data="monthlyAccountsData" :options="chartOptions"></bar-chart>
          <p v-else>Loading monthly data...</p>
        </div>
      </div>
    </div>

          <!-- ... rest of the existing content ... -->
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import BarChart from '@/components/shared/chart/BarChart.vue'
import {getAPI} from "@/axios-api";
export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    BarChart,
    // ... other components ...
  },
  data() {
    return {
      // totalUserAccounts: 0,
      // totalOrganisationAccounts: 0,
      // usersOnline: 0,
      // dailyAccountsData: {},
      // weeklyAccountsData: {},
      // monthlyAccountsData: {},
      totalUserAccounts: 1250,
      totalOrganisationAccounts: 75,
      usersOnline: 42,
      dailyAccountsData: null,
      weeklyAccountsData: null,
      monthlyAccountsData: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    }
  },
  mounted() {
     this.fetchDashboardData()
  },
  methods: {
    // async fetchDashboardData() {
    //   try {
    //     // Replace with actual API calls
    //     const response = await this.$api.getDashboardData()
    //     this.totalUserAccounts = response.totalUserAccounts
    //     this.totalOrganisationAccounts = response.totalOrganisationAccounts
    //     this.usersOnline = response.usersOnline
    //     this.dailyAccountsData = response.dailyAccountsData
    //     this.weeklyAccountsData = response.weeklyAccountsData
    //     this.monthlyAccountsData = response.monthlyAccountsData
    //   } catch (error) {
    //     console.error('Error fetching dashboard data:', error)
    //   }
    // }
    async fetchDashboardData() {
      try {
        const response = await getAPI.get(`other/stat-admin/`);
        if (response.status === 200 && response.data) {
          const data = response.data.data;
          console.log("jjj",data);
          this.totalOrganisationAccounts = data.nb_org;
          this.totalUserAccounts = data.nb_user_account_active;
          this.usersOnline = data.online_users_count;
          
          if (Array.isArray(data.accounts_create_per_day)) {
            this.dailyAccountsData = this.processChartData(data.accounts_create_per_day, 'date');
          }
          if (Array.isArray(data.accounts_create_per_week)) {
            this.weeklyAccountsData = this.processChartData(data.accounts_create_per_week, 'week');
          }
          if (Array.isArray(data.accounts_create_per_month)) {
            this.monthlyAccountsData = this.processChartData(data.accounts_create_per_month, 'month');
          }

          console.log('Daily data:', this.dailyAccountsData);
          console.log('Weekly data:', this.weeklyAccountsData);
          console.log('Monthly data:', this.monthlyAccountsData);
        } else {
          console.error('Invalid response from API:', response);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    },
    processChartData(data, type) {
      if (!Array.isArray(data) || data.length === 0) {
        console.warn(`No data available for ${type} chart`);
        return { labels: [], datasets: [] };
      }
      
      return {
        labels: data.map(item => this.formatLabel(item[type], type)),
        datasets: [{
          label: 'New Accounts',
          data: data.map(item => item.created_count || 0),
          backgroundColor: '#25c2cb'
        }]
      }
    },
    formatLabel(value, type) {
      switch (type) {
        case 'date':
          return new Date(value).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
        case 'week':
          return `Week ${value.split('-')[2]}`
        case 'month':
          return new Date(value + '-01').toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
        default:
          return value
      }
    }
    
   
  }
}
</script>

<style scoped>
/* ... existing styles ... */

.fas {
  font-size: 24px;
  color: #25c2cb;
}
</style>

<template>
  <div class="image-upload-container">
    <div class="image-upload-square" @dragover="onDragOver" @drop="onDrop" @click="onClick">
      <input type="file" ref="fileInput" accept="application/pdf" @change="onFileChange" style="display: none;" />
      <div class="px-4">
        Drag a <span class="pdftag">PDF</span> file here or click to select a file to upload.
      </div>
    </div>
    <div style="height: 60vh; width: 100%; background-color: #fff;" class="mt-1">
      <div v-if="isLoading" class="loading-indicator">
        <div class="spinner"></div>
        <div>Loading PDF...</div>
      </div>
      <iframe v-else-if="pdfUrl" :src="pdfUrl" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentInput',
  data() {
    return {
      pdfUrl: null,
      isLoading: false,
    };
  },
  methods: {
    onDragOver(event) {
      event.preventDefault();
    },
    onDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.handleFile(file);
    },
    onClick() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.handleFile(file);
    },
    handleFile(file) {
      if (!file) {
        return;
      }
      if (!file.type.startsWith('image/') && file.type !== 'application/pdf') {
        // handle invalid file type
        return;
      }
      this.isLoading = true;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.pdfUrl = event.target.result;
        this.isLoading = false;
        document.getElementsByClassName('image-upload-square')[0].style.backgroundColor = "#e4ffc6"
        this.$emit('pdf-loaded', file);
      };
      reader.onerror = () => {
        this.isLoading = false;
        this.$emit('pdf-loaded', file);
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style>
    .pdftag{
        padding: .1em .2em;
        background-color: #fff;
        color: #000;
        border-radius: 5px;
    }

    .image-upload-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .image-upload-square {
        width: 100%;
        height: 50px;
        background-color: #f5f5f5;
        border: 3px dashed #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 6px;
        transition: all .2s ease-in;
        cursor: pointer;
    }

    .image-upload-square:hover{
        border-color: #06bbc4;
        background-color: #fff;
    }

    .image-upload-square img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>

<template>
  <div>
    <div v-if="true" class="cfa-bg-secondary cfa-footer-wrapper">
      <div class="container_ col-12 p-0 p-md-4 d-flex flex-md-row flex-column cfa-footer">
        <div class="col-md-5 col-12 text-start px-5 cfa-footer-brand">
          <img class="col-5 col-md-9" src="@/assets/brand/spec-brand-2.svg"/>
          <!-- <p class="cfa-newsletter-text mt-4 w-75">Ne manquez  aucune mise à jour sur la CyberSécurité et recevez des offres exclusives</p>
          <-- <p class="cfa-newsletter-text mt-4 w-75">Abonnez-vous et restez au courant de tout ce qui concerne la cybersécurité!</p> --
          <news-letter class="mt-3 w-75 d-none d-md-block"></news-letter>
          <div class="cfa-newsletter-text mt-4 w-75">
            <input type="checkbox" id="acceptance" class="d-inline h-auto"/>
            <label for="acceptance" class="d-inline">
              En soumettant ce formualaire, vous acceptez notre
              <a href="/mentions#politique-confidentialites" class="cfa-primary">politique de confidentialité</a> et de <a href="/mentions#coockies" class="cfa-primary">coockies</a>
              et accepez de reçevoir des messages de Spectorly.
            </label>
          </div>
          <social-icon class="mt-3 w-75 d-none d-md-block"></social-icon>
          <hr class="d-block d-md-none">-->
        </div>

        <div class="col-md-2 col-12" style="padding-top: 20px;">
          <ul>
            <!--
            <li><strong>RESSOURCES</strong></li>
            <li><strong>A PROPOS</strong></li>
            <li><a href="">Qui sommes-nous</a></li>
            <li><a href="">Trarification</a></li>
            <li><a href="">Audit de Phishing</a></li>
            <li><a href="">Audit SMShing</a></li>
            <li><a href="">Demandez une Démo</a></li>
            <li><a href="">Presse</a></li>
            <-- <li><a href="">Blog</a></li> -->
          </ul>
        </div>

        <div class="col-md-2 col-12">
          <ul>
            <!-- <li><a href="">Formation</a></li> -->
            <!-- <li><a href="">Cyber Métaverse</a></li> -->
            <!-- <li><a href="">Cyber Sécurité</a></li> -->
            <!-- <li><a href="">Vérification de Certificat</a></li> -->
            <!-- <li><a href="">Blog</a></li> -->
            
          </ul>
        </div>
        
        <div class="col-md-3 col-12" style="padding-top: 20px;">
          <ul>
            <li style=" display: inline;"><a href="">Cookies et Sécurité</a></li><br/><br/>
            <li><a href="">Politique de confidentialité et de Conditions d’utilisation</a></li>
            <!--
            <li><strong>CONTACT</strong></li>
            <li><a href="mailto:hello@spectorly.com">hello@spectorly.com</a></li>
            <li>(WhatsApp, Skype, Viber,)</li>
            <li><a href="">user@cyberforce.io</a></li>
            <li>East Rampura, Dhaka Mobile</li>
            <li>111 France</li> -->
          </ul>
        </div>

          <div class="d-block d-md-none col-12 text-start px-5">
            <hr>
            <news-letter class="mt-3 w-100"></news-letter>
            <social-icon class="mt-3 w-100"></social-icon>
            <select-button @action="changeLang" :dropDownLabel="['🇨🇵️ Fr', '🇺🇸️ En']" :dropDownLink="['go-to-fr', 'go-to-en']" label="<span>LANGUE </span><i class='fa fa-globe' style='font-size: 1.4em; color: #06bbc4 !important; position: relative; top: .05em;'></i>" uid="cfa-lang-swiper-bottom" :showArrow="false" cssStyle="border: none !important; position: absolute !important;right: 0em;width: 8em;bottom: 1em;" cssClass="d-md-none d-block"></select-button>
          </div>
        </div>
      <center><span style="font-weight: bold" class="ps-4">© 2023 CyberSpector - <a style="color: var(--primary-color);" href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/" target="_blank" class="text-decoration-none">Mention Légale</a> - <a style="color: var(--primary-color);" href="https://cyberspector.com/politique-de-confidentialite-et-de-protection-des-donnees/" target="_blank" class="text-decoration-none">Conditions Générales d’utilisation<br/><br/></a></span></center>
    </div>
    <!-- <div class="p-2 px-5 text-center d-flex justify-content-md-between justify-content-center">
      <span v-if="true" class="pe-4">Made with ❤️ FROM {{ this.country_maker[0] }}</span> .join(', ') 
    </div> -->
    <!--<ai-chat></ai-chat>-->
  </div>
</template>

<script>
import NewsLetter from '@/components/shared/NewsLetter.vue'
import SocialIcon from '@/components/shared/SocialIcon.vue'
// import AiChat from '@/components/shared/chat/AiChat.vue'
import SelectButton from '@/components/shared/button/SelectButton.vue'

export default {
  name: 'CFAFooter',
  components: {
    NewsLetter,
    SocialIcon,
    // AiChat,
    SelectButton
  },
  data () {
    return {
      country_maker: ['Bénin', 'France', 'Rwanda']
    }
  },
  methods: {
      shuffle (array){
      let currentIndex = array.length, ramdomIndex

      while (currentIndex != 0) {
        // pick a ramaining element
        ramdomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // swap it with the current one
        [array[currentIndex], array[ramdomIndex]] = [
          array[ramdomIndex], array[currentIndex]
        ]
      }

      return array
    },
      changeLang (e) {
          // alert('ok')
          if(localStorage.language == 'en' && e == "go-to-fr") {
              localStorage.setItem('language', 'fr')
          }
          if(localStorage.language == 'fr' && e == "go-to-en") {
              localStorage.setItem('language', 'en')
          }
          this.$i18n.locale = localStorage.language
          this.lang = (localStorage.language == 'en') ? 'EN' : 'FR'
      },
  },
  mounted () {
    setInterval(()=>{
      this.shuffle(this.country_maker)
    }, 2000)

    // TODO: Temp Patch for content protection
    /*window.addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });
    window.addEventListener('keydown', (e) => {
        if (e.ctrlKey && (e.key === 'u' || e.key === 'U' || e.key === 'c' || e.key === 'C' || e.key === 'i' || e.key === 'I')) {
            e.preventDefault();
        }
    });*/

      /*const script = document.createElement('script')
      script.src = 'https://cdn.jsdelivr.net/gh/yasserelsaid/chatbot@latest/index.min.js'
      script.id = 'botnet-docx-k9-bwex22'
      document.body.appendChild(script)

      const checkChatbaseButton = () => {
          const chatbaseButton = document.getElementById('chatbase-bubble-button')
          if (chatbaseButton) {
              chatbaseButton.style.background = '#06bbc4'
              return
          }
          setTimeout(checkChatbaseButton, 1000)
      }
      checkChatbaseButton()*/
  }
}
</script>

<style scoped>
.cfa-footer div ul li strong, .cfa-footer div ul li a{
  color: #fff;
  text-decoration: none;
}
.cfa-footer div ul{
  list-style-type: none;
  text-align: left;
  font-size: 1.1em;
}
.cfa-footer div ul li strong{
  font-size: 1.5em;
}
.cfa-footer div ul li{
  margin: .5em;
}
.cfa-newsletter-text{
  font-size: 1.1em;
}
.cfa-footer div ul li a:hover{
  color: var(--primary-color);
  text-decoration: underline;
}
.cfa-footer{
  padding-top: 3rem !important;
  padding-bottom: 1rem !important;
}
.cfa-footer-brand{
  /*border-bottom: 1px solid #ddd;*/
}
#chatbase-bubble-button{
  background: var(--primary-color);
}
.cfa-footer-wrapper{
}
</style>

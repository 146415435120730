<template>
  <div class="cfa-root">
    <c-f-a-nav>
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3">
          <h1 class="cfa-section-title">PROFIL DE VOTRE ENTREPRISE OU ORGANISATION</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <div class="page animsition" style="animation-duration: 0s; opacity: 1;">
            <div class="page-content container-fluid ">
              <div class="row">
                <!--section 0-->
                <div class="cfa-block p-4">
                  <div>
                    <div class="d-flex align-items-center justify-content-between">
                      <h4 class="mb-0">Détails</h4>
                      <a @click="showModal=true" class="btn cfa-btn-primary d-flex align-items-center" style="font-weight: bold;">
                        <i class="fa fa-image margin-right-10 d-none d-md-block" aria-hidden="true">&nbsp;</i>
                        <span class="d-inline">CHANGER LOGO</span>
                      </a>
                    </div>
                    <section class="text-center py-4 mt-4 cfa-bg-secondary" style="border-radius: 0.5em;">
                      <div >
                        <img class="bg-white p-1" width="150" style="border-radius: 100%;" src="https://picsum.photos/200/200" alt="logo company"/>
                      </div>
                    </section>
                    <div class="row">
                      <section class="pb-0 col-12 col-md-6">
                        <form>
                          <div class="tab-pane active d-flex flex-wrap">
                            <div class="col-12">
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>Nom de la Structure</strong></p>
                                <input class="form-control" type="text"  value="CyberSpector" disabled="">
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>Téléphone</strong></p>
                                <input class="form-control" type="text">
                              </div>
                            </div>
                            <div class="col-12 pl-0">
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>Pays</strong></p>
                                <input class="form-control" type="text"  value="">
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group py-3 pe-md-2">
                                <p class="mb-2"><strong>Email</strong></p>
                                <input class="form-control" type="text"  value="">
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                      <section class="pb-0 col-12 col-md-6">
                        <form>
                          <div class="tab-pane active d-flex flex-wrap">
                            <div class="col-12">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>Secteur d'activité</strong></p>
                                <div class="input-group">
                                  <select name="duration" class="form-control" style="width: 100%;">
                                    <option value="15">Selectionner</option>
                                    <option value="15">Vente et restauration</option>
                                    <option value="30">Développement logiciel</option>
                                    <option value="45">Sécurité Informatique</option>
                                    <option value="60">Autres</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>Ville</strong></p>
                                <input class="form-control" type="text"  value="">
                              </div>
                            </div>
                            <div class="col-12 pl-0">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>Adresse Siège Social</strong></p>
                                <input class="form-control" type="text">
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group py-3 ps-md-2">
                                <p class="mb-2"><strong>Site Internet</strong></p>
                                <input class="form-control" type="text"  value="">
                              </div>
                            </div>
                          </div>
                        </form>
                      </section>
                    </div>
                    <div class="mt-4 d-flex justify-content-between align-items-center">
                      <div class="text-left"></div>
                      <button id="validateButton-pass" class="btn btn-round cfa-btn-primary" type="submit">
                        Valider
                      </button>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-between flex-wrap px-0 mt-2">
                  <div class="col-12 col-md-6 ps-md-0 pe-md-3">
                    <div class="cfa-block p-4 mt-4">
                      <div>
                        <div class="mb-4">
                          <h4>
                            Contact Administratif
                          </h4>
                        </div>
                        <section>
                          <form>
                            <div>
                              <div class="col-12 pl-0">
                                <div class="form-group py-3 pe-0 pe-md-2">
                                  <p class="mb-2"><strong>Nom et Prénoms</strong></p>
                                  <input class="form-control" type="text"  value="">
                                </div>
                              </div>
                              <div class="d-flex align-items-center">
                                <div class="col-6 pl-0">
                                  <div class="form-group py-3 pe-0 pe-md-2">
                                    <p class="mb-2"><strong>Email</strong></p>
                                    <input class="form-control" type="text"  value="">
                                  </div>
                                </div>
                                <div class="col-6 pl-0">
                                  <div class="form-group py-3 pe-0 pe-md-2">
                                    <p class="mb-2"><strong>Téléphone</strong></p>
                                    <input class="form-control" type="text"  value="">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </section>
                        <div class="col-12 pl-0">
                          <div class="form-group py-3 pe-0 pe-md-2">
                            <p class="mb-2"><strong>&nbsp;</strong></p>
                            <div class="d-flex justify-content-end">
                              <button id="validateButton-pass" class="btn btn-round cfa-btn-primary" type="submit">
                                Valider
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 pe-md-0 ps-md-3">
                    <div class="cfa-block p-4 mt-4">
                      <div>
                        <h4 class="pb-4">Contact de Facturation </h4>
                        <div>
                          <form method="post" action="">
                            <div class="row">
                              <div class="col-12 pl-0">
                                <div class="form-group py-3 pe-0 pe-md-2">
                                  <p class="mb-2"><strong>Noms et Prénoms</strong></p>
                                  <input class="form-control" type="text">
                                </div>
                              </div>
                              <div class="col-12 pl-0">
                                <div class="form-group py-3 pe-0 pe-md-2">
                                  <p class="mb-2"><strong>Email</strong></p>
                                  <input class="form-control" type="text">
                                </div>
                              </div>
                              <div class="col-12 pl-0">
                                <div class="form-group py-3 pe-0 pe-md-2">
                                  <p class="mb-2"><strong>&nbsp;</strong></p>
                                  <div class="d-flex justify-content-end">
                                    <button id="validateButton-pass" class="btn btn-round cfa-btn-primary" type="submit">
                                      Valider
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--section 1-->
                

                <!--section 2-->
                

                <!--section 3-->
                <div class="cfa-block p-4 mt-4">
                  <div>
                    <div class="d-flex align-items-center justify-content-between pb-4">
                      <h4 class="">Détails de Licence</h4>
                      <button class="btn text-white" :style="`background-color: ${(true) ? 'green' : 'red'}`" >{{ (true) ? 'Licence Active' : 'Licence Expirée' }}</button>
                    </div>
                    <div>
                      <div class="card">
                          <div class="card-body">
                              <div class="inbox-body">
                                  <div class="table-responsive">
                                      <table class="table table-inbox table-hover text-nowrap mb-0">
                                          <tbody class="ticket-list ticket-list2">
                                              <tr>
                                                  <td class="view-message dont-show fw-semibold clickable-row" >Date d'activation de la licence :</td>
                                                  <td class="view-message text-end fw-semibold clickable-row" >13 Juin 1998</td>
                                              </tr>
                                              <tr>
                                                  <td class="view-message dont-show fw-semibold clickable-row" >Date d'expiration de la licence :</td>
                                                  <td class="view-message text-end fw-semibold clickable-row" >13 Juin 2025</td>
                                              </tr>
                                              <tr>
                                                  <td class="view-message dont-show fw-semibold clickable-row" >Date de finalisation par les utilisateurs :</td>
                                                  <td class="view-message text-end fw-semibold clickable-row" >13 Juin 2025</td>
                                              </tr>
                                              <tr>
                                                  <td class="view-message dont-show fw-semibold clickable-row" >Capacité de la licence :</td>
                                                  <td class="view-message text-end fw-semibold clickable-row" >64</td>
                                              </tr>
                                              <tr>
                                                  <td class="view-message dont-show fw-semibold clickable-row" >Type de plan :</td>
                                                  <td class="view-message text-end fw-semibold clickable-row" >
                                                    <a href="">cyber_plan/152/sales/12-mdj-548</a>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td class="view-message dont-show fw-semibold clickable-row" >Code d’Inscription de votre entreprise :</td>
                                                  <td class="view-message text-end fw-semibold clickable-row" >
                                                    <a href="">CFA2514-CB2541</a>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <modal v-if="showModal" @close="showModal = false">
            <template v-slot:header>
              <h3>Choisissez une avatar</h3>  
            </template>
            <template v-slot:body>
              <div class="col-12 pl-0">
                <div class="form-group ">
                  <label class="control-label" for="inputPassnew">Avatar</label>
                  <div class="input-group">
                    <input class="btn cfa-btn-primary modal-default-button" type="file" name="" id="" value="SELECTIONNER UN AVATAR">
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer></template>
          </modal>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.ticket-list tr {
  border-color: transparent !important;
}
</style>
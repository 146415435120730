<template>
  <div class="w-100 mt-" style="overflow-x: scroll;">
    <div class="col-12 pe-0" style="min-width: 800px;">
      <!-- filter tools -->
      <div v-if="!hideControl" :class="'filter-zone d-flex align-items-center justify-content-between ' + classFilter">
        <div>
          <div>
            <div class="cfa-searchbar-wrapper" >
              <img width="15" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAqCAYAAAAu9HJYAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQxSURBVHgBzVnNVtNAFP5m0lQEwXgU7c6+gXHnjrBziU+g7nRleQLhCYSdO+vSlbB0ZfoExiew7nrERVDk9BDa8d42k0zSAmkpDd+m85PMfJn7N/dWYAo4juv0bGwo4JEEPCXgQKGePCDQpn6b5gMh0LJO4IdhEGJKiEkevnXP9WjTt9T0MCkE9lQfu0e/Ax8TohDJS5EbRTuKsN4Ng3bRF84lyWLt23hLYmuMmW4pBV8oBFEPgbnpguPWK5WB+PnjPPpdG7Pxzp+DYBOXIUkE66R3X6lZN4ZDIrZbOcXOJDrm1GitHrao+Tw3VehURWGCCntRBZvdTnExFSR7IVFRgGDYV9j+9zvYwYywdM9tSIF3KEhU5Aiya/mWIQis/zsIAswYS6uuKxUdBruvIZFAEtFxaiTNDhuJQRBXRZDB6/YF1nWfjNON9x+BpRv8ZfQ1Td0nEW+SiPdwhYiOOx17sXZIHuBpPPSkulhrnRx32uZz0mh8Nsabs9TB8xDv4+t+7I8zGJBcvutuwBBzZGEbc4Rl4SXJW+uix8HDnB+QpNhruoTmZdzMNAhpP9LJXd3Pn6Zkl0ODG3pg3qeoQQdl6r/LnkZ3ZM+Ca0y25n2KGrEX8eOu06um9wRyVWmHYzFKBN2SviPl4um2FBKPjOd8lAiRtfK6brPhJJ3TU/EDJcKykQYOlR5ehmQ3/PYTZaILMySmhoNrhFzcvp4kTbdDSAgzybbuLDiPH6JERIbqCZHykioNR7Cr6jFKhLRSksTrMBknK2olE/2MY587Mj4bqaWzn0w6dMRrKBPG/sTF121pRZmY6fG9EiUgvkMke3NBQbdlbPbJAKWoGygBveytvGm6o+FVTaU3H/qaN5w3Y45whvu90H3i89GcH5CMSx++fse28QFzRM/cT2A/X4pJnLl5mgRv5YHbwBywct/NlG8iOVotSRIxTn6qN2t3SNxPBgMKT8clRbPE8n1KWxTe6z4f1PGv0eQvExYrVF0QyLikz/l8Y2YEV93nlDMnYuZ9Scxb457NkGSLIq//DGlIcojo11mLfmXVfQNOn0VyiQhPeN8zYOUHukedcHG5tk8en13RcBES/Y2lWl1Wa99Pu52pi6FsxfZyjVPnV7mpBUvBIdXaH/fe2VU1Li71qQyiMlU1RpPqNtuT1Bfj+iZnpC9yU5wumJlB8+9B8BJFSRobbI1L2FmH+uy2FPYp0wvtCG1WF75uddmNVeGSIazRcxxFvPz7nMIeHQSNMeuPEC1U6Y1Ldk1gJrG9RR/XMGtMFxG1UACsp6QvTXZJtBh/2KTxPaS3vtDJvmYL5hqQOUlr+7S2iKvCDJdtQOvoRIV9jUGJ0KJ/HSSVm5kwZ3YKtzE0NCZ0SO4lUMN/IfaoMhwUqQyfdaJTkbxK5Ilyda+QuOeJvOjp9xOuK7jSNwibhP+ljMkC4t/IngAAAABJRU5ErkJggg==" alt="search icon">
              <input type="search"  id="filter-input" v-model="filterText" placeholder="Type to filter" style="background-color: rgb(255, 255, 255); border: 1px solid rgb(229, 232, 242);">
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div>
            <label for="range-select" class="table-select-label">Rows per page:</label>
            <select id="range-select" v-model="paginationRange" class="table-select">
              <option v-for="option in rangeOptions" :key="option" :value="option">{{ option }}</option>
            </select>
          </div>
          <div class="ms-1">
            <slot></slot>
          </div>
        </div>
      </div>

      <div v-if="filteredData.length">
        <!-- table -->
        <table class="customized-table">
          <thead class="cfa-block" :style="'background-color: '+headBackground">
            <tr>
              <th v-for="(column, index) in columns" :key="index"  :style="{width: (index === columns.length - 1) ? this.lastCellWidth : '', minWidth: (index === columns.length - 1) ? this.lastCellWidth : ((i == index) ? this.iMinWidth : '')}">{{ column }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredData" :key="index" class="cfa-table-row">
              <td v-for="(column, index) in columns" @click="action($event, column, item)" :key="index" v-html="item[column]"></td>
            </tr>
          </tbody>
        </table>

        <!-- pagination -->
        <div v-if="!hideControl" class="pagination">
          <button class="btn cfa-btn-light" @click="previousPage" :disabled="currentPage === 1">Précédent</button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button class="btn cfa-btn-primary" @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 60vh">
        <div class="d-flex flex-column">
          <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
          <span class="mt-3 fs-5 fw-bold pl-2 text-center empty-label">Aucune donnée.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    columnsAction: {
      type: Array,
      required: true,
    },
    defaultRange: {
      type: Number,
      default: 10,
    },
    lastCellWidth: {
      type: String,
      default: '245px'
    },
    classFilter: {
      type: String,
      default: ''
    },
    iMinWidth: {
      type: String,
      default: '2em'
    },
    i: {
      type: Number,
      default: -1
    },
    hideControl: {
      type: Boolean,
      default: false
    },
    headBackground: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      filterText: '',
      paginationRange: this.defaultRange,
      currentPage: 1,
    };
  },
  computed: {
    filteredData() {
      return this.data.filter(item => {
        return Object.values(item).some(value => {
          return String(value).toLowerCase().includes(this.filterText.toLowerCase());
        });
      });
    },
    rangeOptions() {
      return [10, 20, 50, 100];
    },
    totalPages() {
      return Math.ceil(this.filteredData.length / this.paginationRange);
    },
    visibleData() {
      const startIndex = (this.currentPage - 1) * this.paginationRange;
      const endIndex = startIndex + this.paginationRange;
      return this.filteredData.slice(startIndex, endIndex);
    },
    initListener () {
      alert('o')
      return false;
    }
  },
  methods: {
    previousPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
    action (event, column, item) {
      event.stopPropagation();

      if(this.columnsAction.includes(column)) {
        let elm = event.target
        if (elm.classList.contains('btn-action')) {
          let payload = {
            id: elm.getAttribute('btn-id'),
            action_type: elm.getAttribute('btn-action'),
            name: item.name,
          };
          this.$emit('action', payload)
        }
      }
    }
  },
};
</script>

<style scoped>
  .cfa-searchbar-wrapper {
    min-width: 23em;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    .cfa-searchbar-wrapper[data-v-5a2240de] {
      width: 100%;
      min-width: 10em;
      position: relative;
    }
  }
  .cfa-searchbar-wrapper img {
    position: absolute;
    top: 0.9em;
    bottom: 0.5em;
    left: 1em;
  }
  .cfa-searchbar-wrapper input {
    outline: none !important;
    border-radius: 0.5em;
    padding: 0.9em 1.2em 0.8em 3em;
    width: 100%;
    border: none;
    background-color: #f0f3f8;
    color: #2e354d;
    box-shadow: 0px 0px 35px 7px rgb(246 249 254 / 96%);
  }
  .filter-zone{
    padding: 0.5em;
  }
  .customized-table{
    position: initial;
    margin: 0.5em;
    width: calc(100% - 1em) !important;
  }
  .pagination{
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .pagination span{
    margin: 1em;
  }
  .cfa-block{
    background-color: #fff;
    border-radius: 0.5em;
    padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  }
  .cfa-table-row{
    border-bottom: 1px solid #eceff5;
  }
  .cfa-table-row:last-of-type{
    border-bottom: none;
  }
  .cfa-table-row:hover{
    box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
    -webkit-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
    -moz-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  }
  .empty-label{
    position: relative;
    left: 1em;
  }
  /* extra */
  .cfa-progress-wrapper{
    padding: .5em;
    overflow: hidden;
    cursor: pointer;
    border-radius: .5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .cfa-progress-line{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .cfa-progress-bar2{
    /* min-width: 15em; */
  }
</style>

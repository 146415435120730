<template>
  <div class="cfa-root">
    <c-f-a-nav>
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3">
          <h1 class="cfa-section-title">SUIVI DES UTILISATEURS</h1>
        </div>
        <br>
      </template>
      <template v-slot:content>
        <div class="cfa-page">
          <div class="d-flex justify-content-between align-items-center">
            <search-bar cssStyle="background-color: #fff; border: 1px solid #e5e8f2;"></search-bar>
          </div>
          <div class="w-100 mt-4" style="overflow-x: scroll;"> 
            <div class="col-12 pe-0" style=" overflow: hidden;min-width: 900px;">
              <div class="cfa-block ml-0 gCorner-1100" style="width: 100%;">
                <div class="py-2 row">
                  <div class="text-start col-1 ps-4 text-start"><strong class="cfa-primary">#</strong></div>
                  <div class="text-start col-1"><strong class="cfa-primary">Nom</strong></div>
                  <div class="text-start col-2"><strong class="cfa-primary">Prénoms</strong></div>
                  <div class="text-start col-1"><strong class="cfa-primary">Téléphone</strong></div>
                  <div class="d-none d-md-block text-start col-2"><strong class="cfa-primary">Email</strong></div>
                  <div class="text-end text-md-start col-1"><strong class="cfa-primary">Entreprise</strong></div>
                  <div class="text-end text-md-start col-1"><strong class="cfa-primary">CyberScore</strong></div>
                  <div class="text-end text-md-start col-3"><strong class="cfa-primary">Progression</strong></div>
                </div>
              </div>
              <!-- table -->
              <div v-for="a in [1,1,1,1,1,1,1,1]" :key="a" class="cfa-block ml-0 gCorner-0011" style="box-shadow: none; margin-top: .2em;">
                <div class="py-1 row d-flex align-items-center cfa-user-progress-line">
                  <div class="text-start col-1">
                    <div id="avatarIcon" class="cfa-avatar1 d-flex align-items-center">
                      <img width="25" src='https://picsum.photos/101' alt="avatar"/>
                    </div>
                  </div>
                  <div class="text-start col-1">
                    Tossou
                  </div>
                  <div class="text-start col-2">
                    Jordis
                  </div>
                  <div class="text-start col-1">
                    61205472
                  </div>
                  <div class="text-start col-2 d-flex">
                    someone@gmail.com
                  </div>
                  <div class="d-none d-md-block text-start col-1">
                    OlaSoft
                  </div>
                  <div class="text-start col-1 ms-5 ms-md-0">
                    85%
                  </div>
                  <div class="text-start col-3 ms-5 ms-md-0">
                    <progress-bar-line :percent="24"></progress-bar-line>
                  </div>
                </div>                
              </div>
              <!-- paginator -->
              <div>
                <div class="mb-4">
                  <ul class="pagination float-end">
                    <li class="page-item page-prev disabled">
                      <a class="page-link" href="javascript:void(0)" tabindex="-1">Prev</a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="javascript:void(0)">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">2</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">4</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)">5</a>
                    </li>
                    <li class="page-item page-next">
                      <a class="page-link" href="javascript:void(0)">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import SearchBar from '@/components/shared/SearchBar.vue'
import ProgressBarLine from '@/components/shared/chart/ProgressBarLine.vue'

export default {
  name: 'UserProgressionView',
  components: {
    CFAFooter,
    CFANav,
    SearchBar,
    ProgressBarLine,
  }
}
</script>

<style scoped>
.cfa-page{

}
.cfa-block{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);

  /* border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); */
}
.cfa-avatar1{
  cursor: pointer;
  /* padding: 0.5em; */
  border-radius: .5em;
  /* background-color: #f0f3f8; */
  transition: all ease-in .1s;
  margin-left: 0.5em;
}
.cfa-avatar1:hover{
  background-color: #eceff5;
}
.cfa-avatar1 img{
  border-radius: 100%;
  margin: 0.5em;
}
.cfa-user-progress-line{
  border-bottom: 1px solid #eceff5;
}
.cfa-user-progress-line:last-of-type{
  border-bottom: none;
}
.cfa-user-progress-line:hover{
  box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
}
</style>
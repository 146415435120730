<template>
  <div>
    <div :class="'tagLi ' + cssStyle">
      <span>{{ name }}</span>
      <button v-if="!waitAPICall" @click="deleteTag" class="cfa-icon-btn"><i class="fa fa-close"></i></button>
      <button v-else class="cfa-icon-btn"><img width="15" src="@/assets/img/gif/load2.gif" alt="loader"/></button>
    </div>
  </div>
</template>

<script>
import { getAPI } from '@/axios-api.js'

export default {
  name: 'TagsButton',
  props: {
    cssStyle: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      waitAPICall: false
    }
  },
  methods: {
    deleteTag () {
      this.waitAPICall = true;
      getAPI.delete(`tags/${this.id}/`)
        .then(response => {
          if(response.status == 204) {
            this.waitAPICall = false;
            this.$emit('deletedTag', this.id);
          }
          this.waitAPICall = false;
        })
        .catch(() => {
          this.waitAPICall = false;
        })
    }
  }
}
</script>

<style scope>
.cfa-icon-btn{
  outline: none;
  border: none !important;
}
.cfa-icon-btn:hover{
  background-color: #f0f3f8;
}
.tagLi{
  padding: .3em .5em;
  margin: .3em;
  border-radius: 5px;
  border: 1px solid #ced4da;
  display: inline-block;
}
</style>

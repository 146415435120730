<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title d-none d-md-block">NEWSLETTER</h1>
          <h1 class="cfa-section-title d-md-none d-block">NEWSLETTER</h1>
          <button @click="exportData" class="btn cfa-btn-primary d-flex align-items-center">
            <span class="pt-0">EXPORTER</span> &nbsp; <i class="fa fa-file-zip-o">&nbsp;</i>
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page pt-1">
          <div class="col-12 pe-0 ">
            <!-- ********************** -->
            <Table :columns="subscribeUserColumns" :data="subscribeUser" :columns-action="subscribeUserToListen" @action="getCallToAction1"></Table>
            <!-- ********************** -->
          </div>
          <br>
        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import {getAPI} from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";

export default {
  name: 'NewsletterView',
  components: {
    CFAFooter,
    CFANav,
    Table,
  },
  data () {
    return {
      subscribeUser: [],
      subscribeUserColumns: ['n°', 'Email', 'Nom & Prénom', 'Dernière inscription'],
      subscribeUserToListen: [],

      waitingAPIResponse: true,
      waitingAPIResponse2: true,
      waitingAPIResponse3: true,
    }
  },
  methods: {
    getSubscribeUser () {
      this.waitingAPIResponse = true
      getAPI.get(`newsletter`)
          .then(response => {
            if(response.status == 200) {
              let subscribeUser = response.data.results;
              this.subscribeUser = []
              for(let i=0; i<subscribeUser.length; i++) {
                let row = subscribeUser[i]
                let email = `<a style="color: #0d51f1; font-weight: bold;" href="mailto:${row.email}" target="_blank"><i class="fa fa-envelope"></i> ${row.email}</a>`
                let created_at = this.formatDate(row.created_at)
                this.subscribeUser.push({'n°': (i+1),'Email': email, 'Nom & Prénom': row.firstname + " " + row.lastname, 'Dernière inscription': created_at, name: row.id})
              }
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      return formattedDate;
    },
    exportData () {
      this.download(this.subscribeUser)
    },
    download(json) {
      let text = JSON.stringify(json);
      let filename = `spectorly-newsletter-${(new Date()).getMonth()}-${(new Date()).getFullYear()}.json`;
      let element = document.createElement('a');
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    }
  },
  mounted() {
    this.getSubscribeUser()
  }
}
</script>

<style scoped>
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-certif-state-line:hover{
  /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
-webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
-moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-progress-status{
  min-width: 7em;
}
.item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 0.25em;
}

/* TAB STYLE */
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 10px;*/
  /*border-bottom: 1px solid #ccc;*/
}

.tab-header ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-header ul li {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.tab-header ul li.active {
  background-color: #333;
  color: #fff;
}

.tab-body {
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 0 .5em .5em .5em;
  min-height: 10em;
  /*background-color: #fff;*/
}
</style>

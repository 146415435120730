<template>
  <div class="cfa-root d-flex align-items-center justify-content-center" style="height: 100vh; background-color: #f7f8fc;">
    <div class="leftBlock">
      <!-- brand -->
      <div class="auth-form-header">
        <a href="/"><div class="auth-form-header-illustration mx-auto"></div></a>
        <div class="auth-form-header-separator"></div>
        <span class="auth-form-header-title">Réinitialisation de mot de passe</span>
      </div>
      <!-- form -->
      <div class="auth-form-content">
        <p class="text-left">Définissez ici un nouveau mot de passe pour vous connecter à votre compte.</p>
        <div class="mt-5">
          <modern-input @inputChange="(e)=>{this.register_password1 = e}" placeholder="Mot de passe" errorText="Champ requis" uid="cfa-email-bloc" type="password" :autocomplete="false"></modern-input>
        </div>
        <div class="mt-5">
          <modern-input @inputChange="(e)=>{this.register_password2 = e}" placeholder="Confirmer le Mot de passe" errorText="Champ requis" uid="cfa-firstname-bloc" type="password" :autocomplete="false"></modern-input>
        </div>
      </div>
      <!-- button -->
      <div class="auth-form-actions">
        <button @click="tryResetPassword" type="submit" class="accent light normal oauth-button">
          <span v-if="!waitingAPIResponse">Réinitialiser</span>
          <img v-else src="@/assets/img/gif/load2.gif" alt="loagin gif" style="width: 30px;"/>
        </button>
        <a href="/login" type="button" target="" rel="noopener" class="neutral inverted light oauth-button-light"> J'ai déjà un compte !</a>
      </div>
    </div>
    <div class="rightBlock">
      <img src="https://picsum.photos/700/500"/>
      <p style="px-5 mb-5">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis nobis nihil porro eum illum incidunt aliquam.</p>
      <button type="submit" class="accent light normal oauth-button cfa-bg-secondary mt-3"> En savoir plus</button>
    </div>
    <!-- alert -->
    <transition name="fadeY" appear mode="out-in">
      <alert v-if="alertMe" :status="gAlertType" :message="gAlertMessage"></alert>
    </transition>
  </div>
</template>

<script>
import ModernInput from '@/components/shared/input/ModernInput.vue'
import axios from "axios";
import Alert from '@/components/shared/alert/Alert.vue'

export default {
  name: 'AccountActivationView',
  components: {
    ModernInput,
    Alert,
  },
  data () {
    return {
      register_password1: '',
      register_password2: '',
      token: '',
      alertMe: false,
      gAlertMessage: '',
      gAlertType: '',
      waitingAPIResponse: false
    }
  },
  methods: {
    displayError(error, type='alert-ok', time=5000) {
      this.gAlertMessage = error
      this.gAlertType = type
      this.alertMe = true
      setTimeout(() => {
        this.alertMe = false
      }, time);
    },
    tryResetPassword () {
      if(!this.$route.query.uid || !this.$route.query.token) {
        this.displayError('Oups ! URL invalide.')
      }
      else if(this.register_password1.length < 8 || this.register_password2.length < 8) {
        this.displayError('Oups ! Définissez un mot de passe d\'au moin 8 caractères.', 'alert-no')
      }
      else if(this.register_password1 != this.register_password2) {
        this.displayError('Oups ! Les mots de passe ne correspondent pas.', 'alert-no')
      }
      else{
        this.waitingAPIResponse = true
        axios.post(`${process.env.VUE_APP_BASE_URL}accounts/reset-password/${this.$route.query.token}/${this.$route.query.uid}`,
            {
              password: this.register_password1,
              password2: this.register_password2
            })
            .then(response => {
              if(response.status == 200) {
                this.$emit('reset')
                this.displayError("Super ! mot de passe changé avec succès.", 'alert-yes')
                setTimeout(() => {
                  this.waitingAPIResponse = false
                  let a = document.createElement('a');
                  a.href="/login"
                  a.click()
                  a.remove()
                }, 700);
              }
              else {
                this.displayError('Oups ! something went wrong.', 'alert-no')
                this.waitingAPIResponse = false
              }
            })
            .catch(error => {
              sessionStorage.removeItem('token')
              if (error.response) {
                this.displayError(error.response.data.message, 'alert-no', 10000)
              }
              else if (error.request) {
                this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
              }
              else {
                this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
              }
              this.waitingAPIResponse = false
            })
      }
    }
  },
  mounted() {
    /* && !this.$route.query.user */
    if(!this.$route.query.uid || !this.$route.query.token)  {
      this.displayError('Oups ! URL invalide')
    }
    else{
      this.displayError(`Bienvenu à vous ${this.$route.query.user}`, 'alert-yes');
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .leftBlock{
    width: 100% !important;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .rightBlock{
    display: none !important;
  }
}
.leftBlock{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0em;
  min-width: 30em;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 8px 0 6px #4763881a;
  overflow-y: auto;
  width: 30%;
  z-index: 10;
  background-color: #fff;
}
.rightBlock{
  width: 70%;
  height: 100vh;
  background-color: #f7f8fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rightBlock img{
  margin-bottom: 1em;
}
.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.auth-form-header {
  display: flex;
  flex-direction: column;
  letter-spacing: .5px;
  text-align: center;
}
.auth-form-content{
  width: 22em;
}
.auth-form-header .auth-form-header-illustration {
  /* width: 121px;
  height: 30px; */
  width: 181px;
  height: 40px;
  align-self: center;
  margin-bottom: 20px;
  background-image: url(@/assets/brand/spec-brand-1.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.auth-form-header .auth-form-header-separator {
  width: 200px;
  height: 1px;
  align-self: center;
  /* background: #bfcee1; */
  border-bottom: 1px solid #bfcee1;
}
.auth-form-header .auth-form-header-title {
  margin-top: 20px;
  margin-bottom: 30px;
  color: #476388;
  font-size: 24px;
  white-space: nowrap;
}
.auth-form-link .forgot-password {
  cursor: pointer;
  text-decoration: underline;
}
.auth-form-link a {
  color: #476388;
}
/* auth actions style */
.auth-form-actions {
  display: flex;
  width: 200px;
  height: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.oauth-button{
  background-color: #06bbc4;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  user-select: none;
  position: relative;
  display: flex;
  min-width: 150px;
  height: 34px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 17px !important;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: .5px;
  line-height: 32px;
  text-align: center;
  transition: all .15s cubic-bezier(0,0,.2,.1);
  box-shadow: 0 0 6px #47638840;
}
.oauth-button:hover{
  background-color: #109ca3;
}
.oauth-button-light{
  background-color: #fff;
  color: #6c84a2;
  border: none;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  user-select: none;
  position: relative;
  display: flex;
  min-width: 150px;
  height: 34px;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 17px !important;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: .5px;
  line-height: 32px;
  text-align: center;
  transition: all .15s cubic-bezier(0,0,.2,.1);
  box-shadow: 0 0 6px #47638840;
}
.oauth-button-light:hover{
  color: #06bbc4;
}
</style>

<template>
  <div>
    <Bar
      v-if="loaded"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <p v-else>{{ errorMessage || 'Loading chart...' }}</p>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 200
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loaded: false,
      errorMessage: '',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted() {
    this.loadChart()
  },
  methods: {
    loadChart() {
      this.loaded = false
      this.errorMessage = ''

      console.log('Chart data received:', this.chartData)

      if (!this.chartData) {
        this.errorMessage = 'Chart data is undefined'
        console.error('Chart data is undefined')
        return
      }

      if (!this.chartData.datasets || !Array.isArray(this.chartData.datasets)) {
        this.errorMessage = 'Invalid datasets'
        console.error('Invalid datasets:', this.chartData.datasets)
        return
      }

      if (!this.chartData.labels || !Array.isArray(this.chartData.labels)) {
        this.errorMessage = 'Invalid labels'
        console.error('Invalid labels:', this.chartData.labels)
        return
      }

      this.loaded = true
    }
  },
  watch: {
    chartData: {
      handler() {
        this.loadChart()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
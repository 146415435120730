<template>
    <div>
        <div class="cfa-searchbar-wrapper">
            <img width="15" src="../../assets/Icons/All_Icon/Search.png" alt="search icon"/>
            <input @focus="focus = true" v-model="input" type="search" :style="cssStyle" placeholder="Search"/>
        </div>
    </div>
    <!-- <div class="hide" style="position:relative; right: .5em" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="cfa-searchbar-wrapper" style="position:relative;">
            <i class="search-icon fa fa-search"></i>
            <input @focus="focus = true" v-model="input" type="search" placeholder="Search" class="searchInput"/>
        </div>
        <div v-if="focus">
            <div  class="resultSearch someResult" v-for="result,i in filteredData()" :key="i">
                <a @click.prevent="goTo(result['link'])" href=""><span>🔸️</span>{{ result['label'] }}</a>
            </div>
            <div class="resultSearch noResult" v-if="input && !filteredData().length">
                <p>No results found!</p>
            </div>
        </div>
    </div> -->
</template>

<script>

export default {
    name: 'SearchBar',

    props: {
        data: {
            type: Array,
            default: () => [],
            required: true
        },
        cssStyle: {
            type: String,
            default: ""
        }
    },
    
    data() {
        return {
            input: '',
            focus: false,
            hover: false
        }
    },
    methods: {
        filteredData () {
            if(this.input != "")  {
                return this.data.filter((search) =>
                    ((search.text != undefined) 
                        ? (search.text.toLowerCase()+search.label.toLowerCase()).includes(this.input.toLowerCase())
                        : (search.label.toLowerCase()).includes(this.input.toLowerCase())
                    )
                );
            }
            else{
                return [];
            }
        },
        goTo (link) {
            let a = document.createElement('a')
            a.setAttribute('href', link)
            a.click()
            a.remove()
            this.focus = false
        }
    },
    mounted() {
        // document.getElementsByTagName('html')[0].addEventListener('click', () => {
        //     alert('ok')
        // })
    }
}
</script>

<style scoped>
    @media  only screen and (max-width: 600px) {
        .hide{
            display: none;
        }
    }
    .search-icon{
        position: absolute;
        left: .7em;
        top: .5em;
        bottom: 0;
        color: #2e354d;
        font-size: 1.5em;
    }
    .searchInput{
        padding: 0.5em;
        border: 1px solid #ddd;
        transition: all 1s ease-in;
    }
    /* .searchInput:focus{
        border: 2px solid #ddd !important;
    } */
    .resultSearch{
        position: absolute;
        width: 100%;
        background-color: #fff;
        color: #000 !important;
    }
    .noResult{
        text-align: center;
    }
    .someResult a{
        padding: 2em .8em;
    }
    .someResult {
        padding: .5em 0;
    }
    .noResult p{
        padding: 0.5em;
    }
    .cfa-searchbar-wrapper{
        min-width: 23em;
        position: relative;
    }
    @media  only screen and (max-width: 768px) {
        .cfa-searchbar-wrapper{
            width: 100%;
            min-width: 10em;
            position: relative;
        }
    }
    .cfa-searchbar-wrapper input{
        outline: none !important;
        border-radius: .5em;
        padding: .9em 1.2em .8em 3em;
        width: 100%;
        border: none;
        background-color: #f0f3f8;
        color: #2e354d;
        -webkit-box-shadow: 0px 0px 35px 7px rgba(246,249,254,0.96); 
        box-shadow: 0px 0px 35px 7px rgba(246,249,254,0.96);
    }
    .cfa-searchbar-wrapper img{
        position: absolute;
        top: .75em;
        bottom: .5em;
        left: 1em;
    }
    .cfa-searchbar-btn{
        position: absolute;
        top: .25em;
        bottom: .25em;
        right: .25em;
    }
    input::placeholder {
        color: #2e354d;
        opacity: 1;
    }
</style>
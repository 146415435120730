<template>
  <router-view/>
</template>

<style>
  body{
    /* scale: .9; */
    /* zoom: 90%; */
  }
  /* #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  } */

  /* fadeX */
  .fade-enter-active, .fade-leave-active{
    transition: opacity 1s, transform 1s;
  }
  .fade-enter, .fade-leave-active{
    opacity: 0;
    transform: translateX(20px);
  }
  .fade-move{
    transition: transform 1s;
  }

  /* fadeY */
  /*.fadeY-enter-active, .fadeY-leave-active{
    transition: opacity 1s, transform 1s;
  }
  .fadeY-enter, .fadeY-leave-active{
    opacity: 0;
    transform: translateY(20px);
  }
  .fadeY-move{
    transition: transform 1s;
  }*/

  .fadeY-enter-active {
    transition: all 0.3s ease-out;
  }
  .fadeY-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fadeY-enter-from,
  .fadeY-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }
  /* *** */

  .bounce-enter-active{
    animation: bounce-in .5s;
  }

  .bounce-leave-active{
    animation: bounce-out .5s;
  }

  @keyframes bounce-in{
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce-out{
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.5);
    }

    100% {
      transform: scale(0);
    }
  }

  /* preloader */
  .preloader_center{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .preloader_center img{
    width: 7em;
    z-index: 1111;
  }
</style>

<template>
  <div class="cfa-video-wrapper pt-2">
    <div :id="uid+'-image'" class="w-100 position-relative" :style="'max-height: '+maxHeight+'em;'">
      <img :src="placeholder" style="min-height: 25em;" alt="loading ..."/>
      <div class="cfa-video-control">
        <div class="cfa-play-btn">
          <button :id="uid+'-btn'" class="" @click="playVideo">
            <i class="fa fa-play cfa-primary"></i>
          </button>
        </div>
        <div v-show="showLabel" class="cfa-ceo cfa-bg-primary">
          <p><strong>Ramanou BIAOU</strong></p>
          <hr>
          <p>CEO at CyberSpector</p>
        </div>
      </div>
    </div>
    <div :id="uid+'-iframe'" class="w-100 cfa-iframe d-none" :style="'max-height: '+maxHeight+'em;'">
      <iframe :id="uid+'-video'" width="420" :height="videoHeight" :src="videoLink+'?controls=1'" allowfullscreen></iframe><!--style="pointer-events: none;"-->
    </div>
  </div>
</template>

<script>

export default {
  name: 'CFAVideo',
  props: {
    uid: {
      type: String,
      default: 'cfa-video-player01'
    },
    placeholder: {
      type: String,
      default: 'https://picsum.photos/700/450'
    },
    videoLink: {
      type: String,
      default: 'https://www.youtube.com/embed/iC3eZwI4Lt8'
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    videoHeight: {
      type: Number,
      default: 345
    },
    maxHeight: {
      type: Number,
      default: 26
    }
  },
  data () {
    return {
    }
  },
  methods: {
    playVideo () {
      document.getElementById(this.uid+'-video').src += "&autoplay=1"
      document.getElementById(this.uid+'-btn').classList.add('rainbow')
      setTimeout(() => {
        document.getElementById(this.uid+'-iframe').classList.remove('d-none')
        document.getElementById(this.uid+'-image').classList.add('d-none')
        document.getElementById(this.uid+'-btn').classList.remove('rainbow')
      }, 3000);
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.cfa-video-wrapper div:first-of-type img{
  width: 100%;
}
.cfa-video-wrapper div:first-of-type{
  border-radius: .5em;
  overflow: hidden;
  /* max-height: 26em; */
  background-color: #cef1f3;
}
.cfa-iframe iframe{
  width: 100%;
}
.cfa-iframe{
  border-radius: .5em;
  overflow: hidden;
  /* max-height: 26em; */
  background-color: #cef1f3;
}
.cfa-video-wrapper div:last-of-type{

}
.cfa-video-control{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #13444682 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cfa-play-btn{
  width: 4em;
  height: 4em;
  border-radius: 100% !important;
  padding: 0.5em;
  background-color: #ffffff75 !important;
  transition: all ease-in .3s;
}
.cfa-play-btn:hover{
  background-color: #00bfc6 !important;
}
.cfa-play-btn button{
  border-radius: 100% !important;
  width: 100%;
  height: 100%;
}
.cfa-play-btn button:hover{
  border-color: #00bfc6;
}
.cfa-ceo{
  padding: 0.5em;
  font-weight: bold;
  position: absolute;
  bottom: 1em;
  left: 1em;
}
.cfa-ceo p, hr{
  margin: 0;
  padding: 0;
  font-size: 1.1em;
}
.cfa-ceo hr{
  width: 75%;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}
/* animated border button */
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.rainbow {
  position: relative;
  z-index: 0;
  /* width: 400px;
  height: 300px; */
  border-radius: 10px;
  overflow: hidden;
  /* padding: 2rem; */
  border: 2px solid #cef1f3;
}
.rainbow::before {
  content: '';
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  /* background-color: #399953; */
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  /* background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5); */
  background-image: linear-gradient(#06bbc4, #06bbc4), linear-gradient(#cef1f3, #cef1f3), linear-gradient(#06bbc4, #06bbc4), linear-gradient(#cef1f3, #cef1f3);
  animation: rotate 4s linear infinite;
}
.rainbow::after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: white;
  border-radius: 5px;
  /* animation: opacityChange 3s infinite alternate; */
}
@keyframes opacityChange {
  50% {
    opacity: 1;
	}
  100% {
    opacity: 0.5;
	}
}

/* ---------------------- */
</style>
<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title">GEST. MEMBRES</h1>
          <div></div>
        </div>
      </template>
      <template v-slot:content>
        <div v-show="!waitingAPIResponse" class="cfa-page pt-1">
          <div class="tab">
            <div class="tab-header">
              <ul>
                <li :class="{ 'active': currentTab === 'org' }" @click="currentTab = 'org'"><i class="fa fa-users"></i>&nbsp;Organisations</li>
                <li :class="{ 'active': currentTab === 'individual' }" @click="currentTab = 'individual'"><i class="fa fa-user"></i>&nbsp;Membres individuels</li>
                <li :class="{ 'active': currentTab === 'all_user' }" @click="currentTab = 'all_user'"><i class="fa fa-list"></i>&nbsp;Tous les membres</li>
              </ul>
            </div>
            <div class="tab-body">
              <div v-if="currentTab === 'org'">
                <div class="d-flex align-items-center justify-content-end p-4 mb-5" >
                  <select-button @action="catchAction" label="AJOUTER UN MEMBRE" :dropDownLabel="['Group d\' Utilisateur', 'Un utilisateur']" :dropDownLink="['#user-group', '#unique-user']" cssStyle="background-color: #06bbc4 !important; color: #fff !important;"></select-button>
                    <button @click="this.newModule=true" class="btn cfa-btn-primary" style="height: 2.7em;">
                      CRÉER UNE ORGANISATION <i class="fa fa-plus">&nbsp;</i>
                    </button>
                </div>
                
                  
             
        <DataTable v-model="userAll" :value="orgs" dataKey="id"
                 tableStyle="min-width: 60rem">
            <template #header>
                
                
            </template>
            <!-- <Column expander style="width: 5rem" /> -->
            <Column field="name" header="NOM"></Column>
            <Column field="address" header="ADRESSE"></Column>
            <Column field="phone" header="PHONE">
            </Column>
            <Column field="email" header="MAIL"></Column>
            <Column field="website" header="WEBSITE">                
            </Column>
            <Column :exportable="false" style="min-width:8rem,min-height:10rem" header="Actions">
                      <template #body="slotProps">
                      
                          <!-- {slotProps.data.is_validated_by_admin ==false} ? <Button icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil"></i></Button> :<h5 style="color:cyan;">validated</h5> -->
                          <Button  icon="fa fa-eye-open" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getOrganisationDetails(slotProps.data.id)"  ><i class="fa  fa-eye"></i></Button>
                           <Button  icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-success" @click="getCallToAction(slotProps.data)" ><i class="fa  fa-pencil">Edit</i></Button>
                           <Button v-if="slotProps.data.is_activated == true"  icon="fa fa-pencil" btn-action="view" outlined rounded class="ms-2 btn btn-warning" @click="confirmDeactivate(slotProps.data)" ><i class="fa  fa-check"></i></Button>
                           <Button v-else icon="fa fa-trash" outlined rounded severity="danger" class="ms-2 btn btn-danger" @click="getCallToActions(slotProps.data)" ><i class="fa fa-remove"></i></Button> 
                           
                      </template>
                  </Column>
            
        </DataTable>
        
   

                <!-- <Table-expend  :allow-extension="true" :columns="orgsColumns" :data="orgs" :columns-action="orgsToListen" classFilter=""  @action="getCallToAction">
                  
                </Table-expend> -->
              </div>
              <div v-if="currentTab === 'individual'">
                <Table :i="4" :columns="userIndividualsColumns" :data="userIndividuals" :columns-action="userIndividualsToListen" @action="getCallToActionForUser"></Table>
              </div>
              <div v-if="currentTab === 'all_user'">
                <Table :i="4" :columns="userAllColumns" :data="userAll" :columns-action="userAllToListen" @action="getCallToActionForUser"></Table>
              </div>
            </div>
          </div>

        </div>
        <div v-show="waitingAPIResponse" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>

    <!-- create -->
    <scalable-modal @callback="addNewOrg" :break="exitOrgModal" label="CRÉER UN PLAN" uid="cfa-modal-org-create" @close="newModule=false" :open="newModule" :advancedControl="true" validationButtonText="Créer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div class="row w-100">
          <div class="form-group py-2 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>INTITUTLÉ</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text" v-model="org_name">
            </div>
          </div>
        </div>

<!--        <div class="row w-100 mt-3">-->
<!--          <div class="form-group py-3 pe-0 pe-md-2 ">-->
<!--            <p class="mb-2"><strong>NUM SIRET</strong></p>-->
<!--            <input class="form-control" type="text" v-model="org_numSiret">-->
<!--          </div>-->
<!--        </div>-->

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>SECTEUR D'ACTIVITÉ</strong></p>
            <input class="form-control" type="text" v-model="org_activity">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>VILLE</strong></p>
            <input class="form-control" type="text" v-model="org_city">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>PAYS</strong></p>
            <input class="form-control" type="text" v-model="org_country">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>TELEPHONE</strong></p>
            <input class="form-control" type="text" v-model="org_tel">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>EMAIL</strong></p>
            <input class="form-control" type="email" v-model="org_email">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>SITE WEB</strong></p>
            <input class="form-control" type="text" v-model="org_website">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>LOGO ORGANISATION</strong></p>
            <image-input @image-loaded="onImageLoaded"/>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- update -->
    <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" label="CRÉER UN PLAN" uid="cfa-modal-org-update" @close="openUpdateOrg=false" :open="openUpdateOrg" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div class="row w-100">
          <div class="form-group py-2 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>INTITUTLÉ</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text" v-model="updateDataPayload.name">
            </div>
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>SECTEUR D'ACTIVITÉ</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.activity">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>ADDRESS</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.address">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>TELEPHONE</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.phone">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>EMAIL</strong></p>
            <input class="form-control" type="email" v-model="updateDataPayload.email">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>SITE WEB</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.website">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>VILLE</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.city">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>PAYS</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.country">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>mail administratif</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.email_administratif">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>MAIL FACTURATION</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.email_facturation">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>NOM RESPONSABLE ADMINISTRATIF</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.name_administratif">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>NOM RESPONSABLE FACTURATION</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.name_facturation">
          </div>
        </div>

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>CONTACT ADMINISTRATIF</strong></p>
            <input class="form-control" type="text" v-model="updateDataPayload.phone_administratif">
          </div>
        </div>

        <div class="row w-100 mt-3 d-none">
          <div class="form-group py-3 pe-0 pe-md-2 ">
            <p class="mb-2"><strong>LOGO ORGANISATION</strong></p>
            <image-input @image-loaded="onImageLoaded2"/>
          </div>
        </div>
      </template>
    </scalable-modal>
    <!--De-activate-->
    

    <!-- delete -->
    <scalable-modal @callback="confirmDeletion" :break="exitOrgModalDel" :notifyTaskDone="callbackPayload" :uid="'general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="désactiver" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
      <template v-slot:content>
        <p>Etes-vous sûr de vouloir désactiver cette organisation ?
          <span style="color: red;">Cette Action est irreversible</span></p>
        <div class="col-12 pl-0">
          <div class="form-group py-3 pe-0 pe-md-2">
            <p class="mb-2"><strong>Entrez la raison de la désactivation de l'organisation</strong></p>
            <input class="form-control" type="texte" autocomplete="new-password" v-model="name_check">
          </div>
        </div>
      </template>
    </scalable-modal>

    <div class="gPopupZone">
      <!-- 01 -->
      <scalable-modal @callback="addNewUserToOrg" :break="exitAttach" uid="cfa-modal-004-add-user-to-org-short-version" display-mode="flex" @close="addGroup=false" :open="addGroup" :advancedControl="true" validationButtonText="Ajouter la list d'utilisateur" exitButtonText="Annuler">
        <template v-slot:content>
          <form action="" method="post" enctype="multipart/form-data" style="width: 100%;">
            <div class="w-100 mt-3">
              <div class="form-group py-3 pe-0">
                <p class="mb-2"><strong>CHOISIR UNE ORGANISATION</strong></p>
                <custom-select :options="organisation_plain_list" v-model="selectedOrg" @input="getOrgSelected"></custom-select>
              </div>
            </div>
            <div id="collapse-day-details" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body _p0">
                <div class="form-group ">
                  <span class="d-flex align-items-center justify-content-between">
                    <label class="control-label" for="inputPassnew">Coller dans le champ çi-dessous une liste des adresses emails (Une adresse email par ligne)</label>
                  </span>
                  <br>
                  <div style="min-height: 15vh; width: 100%;" id="studentList">
                    <div class="input-group">
                      <textarea id="mailListContainer" @keyup="checkMailList($event)" v-model="toAdd_email_list" style="min-height: 25em;" class="form-control" ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </template>
      </scalable-modal>
      <!-- 02 -->
      <scalable-modal @callback="addNewUserToOrg" :break="exitAttach" uid="cfa-modal-005 " display-mode="flex" @close="addUser=false" :open="addUser" :advancedControl="true" validationButtonText="Ajouter" exitButtonText="Annuler" :style="'width: 40%; height: auto;'">
        <template v-slot:content>
          <div class="form-group w-100">
            <div class="w-100 mt-3">
              <div class="form-group py-3 pe-0">
                <p class="mb-2"><strong>CHOISIR UNE ORGANISATION</strong></p>
                <custom-select :options="organisation_plain_list" v-model="selectedOrg" @input="getOrgSelected"></custom-select>
              </div>
            </div>
            <br>
            <span class="d-flex align-items-center justify-content-between">
              <label class="control-label" for="inputPassnew">AJOUTER L'ADRESSE EMAIL</label>
            </span>
            <div style="min-height: 15vh" id="studentList">
              <div class="input-group">
                <input id="inputPassnew" class="form-control" name="inputPassnew" type="email"  v-model="toAdd_email">
              </div>
            </div>
          </div>
        </template>
      </scalable-modal>
    </div>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import ScalableModal from '@/components/shared/modal/ScalableModal.vue'
import {getAPI} from "@/axios-api";
import ImageInput from '@/components/shared/input/ImageInput.vue'
//import TableExpend from "@/components/shared/table/TableExpend.vue";
import Table from "@/components/shared/table/Table.vue";
import SelectButton from '@/components/shared/button/SelectButton.vue'
import CustomSelect from "@/components/shared/input/CustomSelect.vue";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import ProgressBarLine from '@/components/shared/chart/ProgressBarLine.vue'

export default {
  name: 'OrganisationView',
  components: {
    CFAFooter,
    DataTable,
    Column,
    CFANav,
    ScalableModal,
    ImageInput,
    //TableExpend,
    SelectButton,
    Table,
    CustomSelect,
    // ProgressBarLine
  },
  data () {
    return {
      exitAttach: null,
      currentTab: 'org',
      newModule: false,
      exitOrgModal: null,
      alertString: '',
      waitingAPIResponse: true,
      allOrganisation: [],

      org_name: '',
      org_numSiret: '',
      org_activity: '',
      org_city: '',
      org_country: '',
      org_address: '',
      org_tel: '',
      org_email: '',
      org_website: '',
      org_logo: '',

      openDeletionBox: false,
      tempDeleteID: '',
      tempDeleteName: '',
      name_check: '',
      callbackPayload: null,
      exitOrgModalDel: null,
      updateDataPayload: null,
      exitOrgUpdateModal: null,
      openUpdateOrg: false,

      orgs: [],
      orgsColumns: ['NOM', 'ACTIVITÉ', 'ADRESSE', 'PHONE', 'MAIL', 'WEBSITE', 'ACTIONS'],
      orgsToListen: ['ACTIONS'],

      userIndividuals: [],
      userIndividualsColumns: ['n°', 'Nom & Prénoms', 'Téléphone', 'Email', 'CyberScore', 'Progression', 'Fin Souscription', 'Actions'],
      userIndividualsToListen: ['Actions'],

      userAll: [],
      userAllColumns: ['n°', 'Nom & Prénoms', 'Téléphone', 'Email', 'CyberScore', 'Progression', 'Fin Souscription', 'Actions'],
      userAllToListen: ['Actions'],

      addUser: false,
      addGroup: false,
      toAdd_email_list: [],
      toAdd_email: null,

      selectedOrg: null,
      organisation_plain_list: [],
      mew_member_email: null
    }
  },
  methods: {
    getOrganisationDetails(id){
      console.log(id);
      this.$router.push({ name: 'organisationDetails', params: { id: id } })
    },
    addNewUserToOrg () {
      console.log(this.selectedOrg)
      if(this.toAdd_email == '') {
        const formData = new FormData();
        formData.append('email', this.toAdd_email);
        formData.append('organisation', this.selectedOrg);

        getAPI.post('accounts/register/add-user', formData, )
            .then(response => {
              if(response.status == 201) {
                //this.getMembers()
                let msg = 'Utilisateur invité avec succès!'
                this.alertString = `${msg}|alert-yes|5000::`+Date.now()
                this.exitOrgModal = Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitOrgModal = Date.now()
            })
            .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitOrgModal = Date.now()
            })
      }
      else {
        let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitOrgModal = Date.now()
      }
    },
    getAllOrgs () {
      this.waitingAPIResponse = true
      getAPI.get('organisations')
        .then(response => {
          if(response.status == 200) {
            this.allOrganisation = response.data.results
            this.orgs = []
            this.organisation_plain_list = []
            console.log(this.allOrganisation.length);
            //let row;
            this.orgs=this.allOrganisation;
            // for(let i=0; i<this.allOrganisation.length; i++) {
            //   row = this.allOrganisation[i]
            //   let name = `<a style="color: #000; text-transform: uppercase; text-decoration: none; font-weight: bold;" href="#"><i class="fa fa-building"></i> ${row.name}</a>`
            //   let btn = `<div class="d-flex align-items-center"><button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-primary btn-action" title="Modifier"> <i class="fa fa-edit"></i> </button>`;
            //   btn += (row.is_active) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action"><i class="fa fa-trash"> SUPPRIMER</i></button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-trash"></i> SUPPRIMER</button></div>`;
            //   this.orgs.push({
            //     'n°': (i+1),
            //     'NOM': name,
            //     'ACTIVITÉ': row.activity,
            //     'ADRESSE': row.address,
            //     'PHONE': row.phone,
            //     'MAIL': `<a href="mailto:${row.email}">${row.email}</a>`,
            //     'WEBSITE': `<a href="${row.website}" target="blank">${row.website.toLowerCase()}</a>`,
            //     'ACTIONS': btn,
            //     name: row.name,
            //     'id': row.id
            //   })
            //   //this.organisation_plain_list.push({id: row.id, label: row.name});
              
            // }
            // console.log(this.orgs);
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          setTimeout(() => {
            this.waitingAPIResponse = false
          }, 2000);
        })
        .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    getMembers (id) {
      this.waitingAPIResponse = true
      getAPI.get(`accounts/users/organisation/${id}`)
          .then(response => {
            if(response.status == 200) {
              let users = response.data.results;
              this.userAll = []
              this.userIndividuals = []
              for(let i=0; i<users.length; i++) {
                let row = users[i]
                let email = `<a style="color: #175ccc; font-weight: bold;" href="mailto:${row.email}">${row.email}</a>`;
                let btn = `<div class="d-flex align-items-center">`;
                btn += (row.is_active) ? `<button btn-id="${row.id}" btn-action="deactivate" class="ms-2 btn btn-success btn-action"><i class="fa fa-eye"> COMPTE ACTIF&nbsp;&nbsp;</i></button>` : `<button btn-id="${row.id}" btn-action="activate" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-eye-slash"></i> COMPTE INACTIF</button>`;
                btn += `</div>`;

                let progress = `<div class="cfa-progress-bar2 my-4 w-100">
                  <div class="d-flex align-items-center w-100">
                    <div class="position-relative cfa-progress-wrapper w-100" style="background-color: rgb(205, 240, 243); height: 1.6em; border-radius: 3px;">
                      <div class="cfa-progress-line" style="background-color: #06bbc4;width:${40}%; height: 1.6em; border-radius: 3px;'"></div>
                    </div>
                    &nbsp;
                    <span style="min-width: 3em;">${ 40 }%</span>
                  </div>
                </div>`;
                // classify and add to array
                this.userAll.push({'n°': (i+1), 'Nom & Prénoms': row.firstname + ' ' + row.lastname, 'Email':email, 'Téléphone':row.phone, 'CyberScore': 0, 'Progression': progress, 'Fin Souscription': 'undefined', 'Actions': btn, name: row.email, id: row.id})
                if(row.type_account == 'individual') {
                  this.userIndividuals.push({'n°': (this.userIndividuals.length+1), 'Nom & Prénoms': row.firstname + ' ' + row.lastname, 'Email':email, 'Téléphone':row.phone, 'CyberScore': 0, 'Progression': progress, 'Fin Souscription': 'undefined', 'Actions': btn, name: row.email, id: row.id})
                }
              }
              console.log(this.users)
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    onImageLoaded (imageData) {
      this.org_logo = imageData
    },
    onImageLoaded2 (imageData) {
      this.updateDataPayload.logo = imageData
    },
    addNewOrg () {
      if(this.org_name != '' && this.org_activity != '' && this.org_city != '' && this.org_country != '' && this.org_tel != '' && this.org_email != '' && this.org_website != '') {
        const formData = new FormData();
        formData.append('name', this.org_name);
        // formData.append('numSiret', this.org_numSiret);
        formData.append('activity', this.org_activity);
        formData.append('city', this.org_city);
        formData.append('country', this.org_country);
        formData.append('address', this.org_country + '/' + this.org_country);
        formData.append('phone', this.org_tel);
        formData.append('email', this.org_email);
        formData.append('website', this.org_website);
        formData.append('logo', this.org_logo);

        getAPI.post('organisations/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if(response.status == 201) {
            this.getAllOrgs()
            let msg = 'Organisation ajouté avec succès!'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            this.exitOrgModal = Date.now()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
          this.exitOrgModal = Date.now()
        })
        .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitOrgModal = Date.now()
            })
      }
      else{
        let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitOrgModal = Date.now()
      }
    },
    deleteData(id, name) {
      this.openDeletionBox = true;
      this.tempDeleteID = id;
      this.tempDeleteName = name
    },
    confirmDeletion () {
      
        getAPI.post(`organisations/${this.tempDeleteID}/deactivate/`, { deactivation_reason: this.name_check })
            .then(response => {
              if(response.status == 200) {
                this.callbackPayload = 'Organisation désactivée avec succès' + '::' + Date.now();
                setTimeout(() => {
                  this.getAllOrgs()
                }, 1500)
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
                this.exitOrgModalDel = Date.now()
              }
            })
            .catch(() => {
              this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
            })
      
    },
    confirmDeactivate (data) {
      
      getAPI.post(`organisations/${data.id}/activate/`)
          .then(response => {
            if(response.status == 201) {
              this.callbackPayload = 'Organisation activée avec succès' + '::' + Date.now();
              setTimeout(() => {
                this.getAllOrgs()
              }, 1500)
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
              // this.exitOrgModalDel = Date.now()
            }
          })
          .catch(() => {
            this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
          })
    
  },
    updateData (pos) {
      this.updateDataPayload = pos
      this.openUpdateOrg = true
    },
    confirmUpdate () {
      delete this.updateDataPayload.logo
      if(this.updateDataPayload.name != '' && this.updateDataPayload.numSiret != '' && this.updateDataPayload.activity != '' && this.updateDataPayload.adress != '' && this.updateDataPayload.phone != '' && this.updateDataPayload.email != '' && this.updateDataPayload.website != '') {
        getAPI.put(`organisations/${this.updateDataPayload.id}/`, this.updateDataPayload)
          .then(response => {
            if(response.status == 200) {
              this.getAllOrgs()
              let msg = 'Organisation MAJ avec succès!'
              this.alertString = `${msg}|alert-yes|5000::`+Date.now()
              this.exitOrgUpdateModal = Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgUpdateModal = Date.now()
          })
          .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
              this.exitOrgUpdateModal = Date.now()
            })
      }
      else {
        let msg = 'Oups ! Veuillez remplir le champ convenablement.'
        this.alertString = `${msg}|alert-no|5000::`+Date.now()
        this.exitOrgUpdateModal = Date.now()
      }
    },
    getCallToActions (payload) {
      
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      
     
    
    },
    getCallToAction (payload) {
        getAPI.get(`organisations/${payload.id}`,)
          .then(response => {
            if(response.status == 200) {
              let data = response.data
              this.updateData(data);
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
          })
          .catch(error => {
              if (error.response) {
                this.alertString = `${error.response.data[0]}|alert-no|10000::`+Date.now()
              }
              else if (error.request) {
                let msg = 'The request was made but no response was received. Please check your network.'
                this.alertString = `${msg}|alert-no|8000::`+Date.now()
              }
              else {
                let msg = 'Oups ! something went wrong.'
                this.alertString = `${msg}|alert-no|5000::`+Date.now()
              }
            })
      
    },
    catchAction (data) {
      if(data == '#user-group') {
        this.addGroup = true
      }
      else if(data == '#unique-user') {
        this.addUser = true
      }
    },
    checkMail (mail) {
      if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true
      }
      return false
    },
    checkMailList (e) {
      if(e.keyCode === 32) {
        this.listOfEmail += '\n';
      }
      let data = this.listOfEmail.split('\n')
      document.getElementById('mailListContainer').style.borderColor = '#b9f5b9'
      for(let i=0; i<data.length; i++) {
        if(!this.checkMail(data[i])) {
          document.getElementById('mailListContainer').style.borderColor = '#ff0000'
          break
        }
      }
      console.log(data)
    },

    getCallToActionForUser (payload) {
      if(payload.action_type == 'activate'||payload.action_type == 'deactivate') {
        getAPI.put(`accounts/users/change-statut/${payload.id}/`, {
          is_active: (payload.action_type == 'activate') ? true : false
        }).then(response => {
          if(response.status == 200) {
            let msg = 'Utilisateur MAJ avec succès.'
            this.alertString = `${msg}|alert-yes|5000::`+Date.now()
            //this.getMembers()
          }
          else {
            let msg = 'Oups ! something went wrong.'
            this.alertString = `${msg}|alert-no|5000::`+Date.now()
          }
        }).catch(() => {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        })
      }
    },
  },
  mounted() {
    this.getAllOrgs()
    //this.getMembers()
  }
}
</script>

<style scoped>
/* TAB STYLE */
.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 10px;*/
  /*border-bottom: 1px solid #ccc;*/
}

.tab-header ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-header ul li {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.tab-header ul li.active {
  background-color: #333;
  color: #fff;
}

.tab-body {
  /*padding: 10px;*/
  border: 1px solid #ccc;
  border-radius: 0 .5em .5em .5em;
  min-height: 10em;
  /*background-color: #fff;*/
}
</style>

<template>
  <div class="cfa-progress-bar2 my-4 w-100">
    <div class="d-flex align-items-center w-100">
      <div class="position-relative cfa-progress-wrapper w-100" :style="(percent != 0) ? 'background-color: '+backgroundColor : 'background-color: #e0e0ec;'">
        <div class="cfa-progress-line" :style="'background-color: '+forgroundColor+';width:'+percent+'%;'"></div>
      </div>
      &nbsp;
      <span style="min-width: 3em;">{{ percent }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBarLine',
  props: {
    percent: {
      type: Number,
      default: 10
    },
    backgroundColor: {
      type: String,
      default: '#cdf0f3'
    },
    forgroundColor: {
      type: String,
      default: '#06bbc4'
    }
  },
  data () {
    return {
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
.cfa-progress-wrapper{
  padding: .5em;
  overflow: hidden;
  cursor: pointer;
  border-radius: .5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.cfa-progress-line{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.cfa-progress-bar2{
  /* min-width: 15em; */
}
</style>
<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div class="w-100 pt-5 text-start mt-2 mb-3 d-flex justify-content-between align-items-center">
          <h1 class="cfa-section-title">PLAN</h1>
          <button @click="this.newPlan=true" class="btn cfa-btn-primary">
            CRÉER UN PLAN <i class="fa fa-plus">&nbsp;</i>
          </button>
        </div>
      </template>
      <template v-slot:content>
        <div class="cfa-page pt-1">

          <!-- ********************** -->
          <Table :columns="plansColumns" :data="plans" :columns-action="plansToListen" @action="getCallToAction1"></Table>
          <!-- ********************** -->

          <br>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>

    <!-- create -->
    <scalable-modal @callback="addNewPlan" :break="exitAddPlanModal" display-mode="flex"  label="CRÉER UN PLAN" uid="cfa-modal-add-plan" @close="newPlan=false" :open="newPlan" :advancedControl="true" validationButtonText="Créer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 5em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <!--<div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>TITRE</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="plan_name">
            </div>
          </div>
        </div>-->

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>CODE</strong></p>
            <input class="form-control" type="text"  v-model="plan_code">
          </div>
        </div>

        <!-- <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>PRIX (FCFA)</strong></p>
            <input class="form-control" type="text"  v-model="plan_price">
          </div>
        </div> -->

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>TYPE</strong></p>
            <select class="form-control" v-model="type_plan">
              <option disabled value="null">--</option>
              <option value="organisation">organisation</option>
              <option value="individual">individual</option>
            </select>
          </div>
        </div>

        <!-- <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>DURÉE (min)</strong></p>
            <input class="form-control" type="text"  v-model="plan_duration">
          </div>
        </div> -->

        <!--
        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>CREDIT</strong></p>
            <input class="form-control" type="text"  v-model="credit_plan">
          </div>
        </div>
        -->

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>NOMBRE D'UTILISATEURS</strong></p>
            <input class="form-control" type="text"  v-model="user_nbres">
          </div>
        </div>

        <!--
        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>FEATURES</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="plan_features">
            </div>
          </div>
        </div>
        -->

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>CREDIT SMSHING</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="plan_smshing_credit">
            </div>
          </div>
        </div>

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>CREDIT PHISHING</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="plan_phishing_credit">
            </div>
          </div>
        </div>

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>PRIX MOIS</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="plan_price_month">
            </div>
          </div>
        </div>

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>PRIX ANNÉE</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="plan_price_year">
            </div>
          </div>
        </div>
        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>FEATURE PLAN</strong></p>
            <!-- <select class="form-control " multiple  v-model="plan_fea">
              <option disabled value="null">--</option>
            
              <option v-for="service  in plan_features" :key="service.id" :value="service.id">{{ service.name }}</option>
             
            </select> -->
            <MultiSelect v-model="plan_fea" :options="plan_features" optionValue="id" optionLabel="name" placeholder="Feature plan"
    :maxSelectedLabels="3" class="form-control" />
          </div>
        </div>
        <!-- <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>TYPE</strong></p>
            <select class="form-control" v-model="plan_type">
              <option value="null">--</option>
              <option value="organisation">organisation</option>
              <option value="individual">individual</option>
            </select>
          </div>
        </div> -->

        <!-- <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>MODULES</strong></p>
            <m-select-input @list="getSelectedModules" />
          </div>
        </div> -->
      </template>
    </scalable-modal>

    <!-- delete -->
    <scalable-modal @callback="confirmDeletion" :break="exitRoleModalDel" :notifyTaskDone="callbackPayload" :uid="'general-deletion-modal'" @close="openDeletionBox=false" :open="openDeletionBox" :advancedControl="true" validationButtonText="Supprimer" exitButtonText="Annuler" displayMode="flex" :style="'width: 30em; height: 22em;'">
      <template v-slot:content>
        <p>Etes vous sûr de vouloir supprimer cette image publicitaire? <span style="color: red;">Cette Action est irreversible</span></p>
        <div class="col-12 pl-0">
          <div class="form-group py-3 pe-0 d-flex align-items-center">
            <input id="deleteIt" type="checkbox" v-model="plan_deletion_check">
            <label class="ms-2" for="deleteIt" ><strong>Je sais ce que je fais </strong></label>
          </div>
        </div>
      </template>
    </scalable-modal>

    <!-- update -->
    <scalable-modal @callback="confirmUpdate" :break="exitOrgUpdateModal" display-mode="flex" label="CRÉER UN PLAN" uid="cfa-modal-plan-update" @close="openUpdatePerm=false" :open="openUpdatePerm" :advancedControl="true" validationButtonText="Enregistrer" exitButtonText="Annuler" :style="'width: 50em; height: auto; margin-top: 2em; margin-bottom: 2em; background-color: #f0f0f0;'">
      <template v-slot:content>
        <!-- blo1 -->
        <div v-show="waitingAPIResponse2" class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
        <div v-show="!waitingAPIResponse2" class=" w-100">
          <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>CODE</strong></p>
            <input class="form-control" type="text"  v-model="selectedPlan.code">
          </div>
        </div>

        <!-- <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>PRIX (FCFA)</strong></p>
            <input class="form-control" type="text"  v-model="plan_price">
          </div>
        </div> -->

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>TYPE</strong></p>
            <select class="form-control" v-model="selectedPlan.type_plan">
              <option disabled value="null">--</option>
              <option value="organisation">organisation</option>
              <option value="individual">individual</option>
            </select>
          </div>
        </div>

        <!-- <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>DURÉE (min)</strong></p>
            <input class="form-control" type="text"  v-model="plan_duration">
          </div>
        </div> -->

        <!--
        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>CREDIT</strong></p>
            <input class="form-control" type="text"  v-model="credit_plan">
          </div>
        </div>
        -->

        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0 ">
            <p class="mb-2"><strong>NOMBRE D'UTILISATEURS</strong></p>
            <input class="form-control" type="text"  v-model="selectedPlan.user_nbres">
          </div>
        </div>

        <!--
        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>FEATURES</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="plan_features">
            </div>
          </div>
        </div>
        -->

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>CREDIT SMSHING</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="selectedPlan.credit_smshing">
            </div>
          </div>
        </div>

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>CREDIT PHISHING</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="selectedPlan.credit_phishing">
            </div>
          </div>
        </div>

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>PRIX MOIS</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="selectedPlan.price_month">
            </div>
          </div>
        </div>

        <div class="row w-100">
          <div class="form-group py-2 pe-0">
            <p class="mb-2"><strong>PRIX ANNÉE</strong></p>
            <div class="d-flex flex-row">
              <input class="form-control" type="text"  v-model="selectedPlan.price_year">
            </div>
          </div>
        </div>
        <div class="row w-100 mt-3">
          <div class="form-group py-3 pe-0">
            <p class="mb-2"><strong>FEATURE PLAN</strong></p>
           <!-- <select class="form-control " multiple  v-model="selectedPlan.features_plan">
              
              <option disabled value="null">--</option>
            
            <option v-for="service  in plan_features" :key="service.id" :value="service.id">{{ service.name }}</option>
            </select> --> 
            <MultiSelect v-model="selectedPlan.features_plan" :options="plan_features" optionValue="id" optionLabel="name" placeholder="Select Cities"
    :maxSelectedLabels="3" class="form-control" />
          </div>
        </div>
        </div>
      </template>
    </scalable-modal>
  </div>
</template>

<script>

import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'

import ScalableModal from '@/components/shared/modal/ScalableModal.vue'

import MultiSelect from 'primevue/multiselect';

import {getAPI} from "@/axios-api";
import Table from "@/components/shared/table/Table.vue";

export default {
  name: 'PlanView',
  components: {
    CFAFooter,
    CFANav,
    Table,
    ScalableModal,
    MultiSelect,
    
   
    //MSelectInput,
  },
  data () {
    return {
      
      alertString: null,
      newPlan: false,
      plans: [],
      plansColumns: ['n°', 'Nom', 'Prix/anneé','Description', 'Types', 'Nombre d`utilisateurs' ,'Autres'],
      plansToListen: ['Autres'],

      openDeletionBox: null,
      tempDeleteID: null,
      tempDeleteName: '',

      waitingAPIResponse: true,
      waitingAPIResponse2: true,
      waitingAPIResponse3: true,
      selectedPlan: null,

      exitAddPlanModal: null,
      options: ['list', 'of', 'options'],

      plan_name: '',
      plan_price: '',
      plan_duration: '',
      plan_type: '',
      plan_modules: [],
      plan_modules_update: [],
      feature_plan:[],
      plan_code: '',
      type_plan: '',
      credit_plan: '',
      user_nbres: '',
      plan_fea:[],
      callbackPayload: null,
      exitRoleModalDel: null,
      plan_deletion_check: null,
      openUpdatePerm: false,
      exitOrgUpdateModal: null,

      plan_smshing_credit: '',
      plan_phishing_credit: '',
      plan_features: [],
      plan_price_month: null,
      plan_price_year: null
    }
  },
  methods: {
    getPlans () {
      this.waitingAPIResponse = true
      getAPI.get(`plans/`)
          .then(response => {
            if(response.status == 200) {
              let plans = response.data.results;
              this.plans = []
              for(let i=0; i<plans.length; i++) {
                let row = plans[i]
                let autres = `<div class="d-flex align-items-center">
                                <button btn-id="${row.id}" btn-action="view" class="btn cfa-btn-primary btn-action"><i class="fa fa-edit"></i></button>
                                <button btn-id="${row.id}" btn-action="delete" class="ms-2 btn cfa-btn-danger btn-action"><i class="fa fa-trash"></i></button>
                           </div>`

                // let modules = '<div class="text-start col-12 d-flex flex-wrap">';
                // for (let j = 0; j < row.modules.length; j++) {
                //   modules += `<div class="item">Module ${row.list_modules[j].number}</div>`;
                // }
                // modules += '</div>'

                // let modules = '<div class="text-start col-12 d-flex flex-wrap">';
                // if (!row.modules || row.modules.length === 0) {
                //   modules = "Aucun module disponible";
                // } else {
                //   for (let j = 0; j < row.modules.length; j++) {
                //     modules += `<div class="item">Module ${row.list_modules[j].number}</div>`;
                //   }
                // }
                // modules += '</div>';
                
                  

                this.plans.push({'n°': (i+1),'Nom':row.name, 'Prix/anneé': row.price_year || row.default_price, 'Description':row.description, 'Types':row.type_plan, 'Nombre d`utilisateurs':row.user_nbres, 'Autres':autres, name: row.id})
              }
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    getFeaturePlans () {
      this.waitingAPIResponse = true
      getAPI.get(`features-plan/`)
          .then(response => {
            if(response.status == 200) {
             this.plan_features = response.data.results;
              console.log(this.plan_features);
              
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    getCallToAction1 (payload) {
      console.log(payload)
      if(payload.action_type == 'delete') {
        this.openDeletionBox = true;
        this.tempDeleteID = payload.id;
        this.tempDeleteName = payload.name;
      }
      if(payload.action_type == 'view') {
        this.updateData(payload.id);
      }
    },
    updateData (id) {
      this.tempUpdateRoleId = id
      this.waitingAPIResponse2 = true
      this.openUpdatePerm = true
      getAPI.get(`plans/${id}/`)
          .then(response => {
            if(response.status == 200) {
              this.selectedPlan = response.data;
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse2 = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse2 = false
          })
    },
    getSelectedModules (payload) {
      console.log(payload)
      this.plan_modules = [];
      for (let i = 0; i < payload.length; i++) {
        this.plan_modules.push(payload[i].id)
        console.log(payload[i])
        console.log(this.plan_modules)
      }
    },
    getSelectedModulesUpdate (payload) {
      console.log(payload)
      this.plan_modules_update = [];
      for (let i = 0; i < payload.length; i++) {
        this.plan_modules_update.push(payload[i].id)
      }
      this.selectedPlan.modules = this.plan_modules_update
    },
    addNewPlan () {
      
    //  this.feature_plan.push(this.plan_fea);
      
      getAPI.post('plans/', {
        //code: "prod_P1rmN8wbFpVE69",
        
        code: this.plan_code,
        type_plan: this.type_plan,
        //credit: this.credit_plan,
        user_nbres: this.user_nbres,
        credit_smshing: this.plan_smshing_credit,
        credit_phishing: this.plan_phishing_credit,
        features_plan: this.plan_fea,
        price_month: this.plan_price_month,
        price_year: this.plan_price_year,
        // name: this.plan_name,
        // name_en: this.plan_name,
        // price: this.plan_price,
        // duration: this.plan_duration,
        // type_plan: this.plan_type,
        // modules: this.plan_modules
      })
      .then(response => {
       console.log(this.feature_plan);
        console.log(this.plan_code)
        console.log(this.type_plan)
        console.log(this.credit_plan)
        console.log(this.user_nbres)
        if(response.status == 201) {
          this.getPlans()
          let msg = 'Plan ajouté avec succès!'
          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.exitAddPlanModal = Date.now()
      })
      .catch(error => {
        console.log(this.plan_code)
        console.log(this.type_plan)
        console.log(this.credit_plan)
        console.log(this.user_nbres)
        if (error.response) {
          let firstError = Object.values(error.response.data)[0][0]
          this.alertString = `${firstError}|alert-no|10000::`+Date.now()
        }
        else if (error.request) {
          let msg = 'The request was made but no response was received. Please check your network.'
          this.alertString = `${msg}|alert-no|8000::`+Date.now()
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.exitAddPlanModal = Date.now()
      })
    },
    confirmDeletion () {
      if(!this.plan_deletion_check) {
        this.callbackPayload = 'Veuillez confirmer cette action.' + '::-1::' + Date.now();
      }
      else{
        getAPI.delete(`plans/${this.tempDeleteID}`)
          .then(response => {
            if(response.status == 204) {
              this.callbackPayload = 'Plan supprimé avec succès' + '::' + Date.now();
              setTimeout(() => {
                this.getPlans()
              }, 1500)
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
              this.exitRoleModalDel = Date.now()
            }
          })
          .catch(() => {
            this.callbackPayload = 'Oups ! something went wrong.' + '::-1::' + Date.now();
          })
      }
    },
    confirmUpdate () {
      delete this.selectedPlan.name
      delete this.selectedPlan.name_en
console.log(this.selectedPlan);
      getAPI.patch(`plans/${this.tempUpdateRoleId}/`, this.selectedPlan).then(response => {
        if(response.status == 201) {
          let msg = 'Plan MAJ avec succès!'
          this.alertString = `${msg}|alert-yes|5000::`+Date.now()
          this.exitOrgUpdateModal = Date.now()
          this.getPlans()
        }
        else {
          let msg = 'Oups ! something went wrong.'
          this.alertString = `${msg}|alert-no|5000::`+Date.now()
        }
        this.exitOrgUpdateModal = Date.now()
      })
          .catch(error => {
            if (error.response) {
              this.alertString = `This plan is already in use !|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.exitOrgUpdateModal = Date.now()
          })
    },
  },
  mounted() {
    this.getPlans()
    this.getFeaturePlans () 
  }
}
</script>

<style scoped>
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
    box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-certif-state-line:hover{
    /* box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -webkit-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87);
  -moz-box-shadow: 20px 12px 109px -37px rgba(0,0,0,0.87); */
  box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(222,234,250,0.87);
}
.cfa-progress-status{
  min-width: 7em;
}
.item {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 0.25em;
}
</style>

<template>
  <div class="user-input">
    <div class="input-container">
      <input class="form-control" type="text" v-model="inputText" @input="filterUsers" placeholder="Type to search for users" />
      <span class="clear-icon" v-if="selectedUser" @click="clearSelection">
        <i class="fa fa-close"></i>
      </span>
    </div>
    <ul>
      <li class="item" v-for="user in filteredUsers" :key="user.id" @click="selectUser(user)">{{ user.email }}</li>
    </ul>
  </div>
</template>

<script>
import {getAPI} from "@/axios-api";

export default {
  name: 'UserInput',
  data() {
    return {
      inputText: '',
      userList: [],
      filteredUsers: [],
      selectedUser: null
    }
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    fetchUsers() {
      this.waitingAPIResponse = true
      getAPI.get('accounts/users/')
          .then(response => {
            if(response.status == 200) {
              this.userList = response.data.results;
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            setTimeout(() => {
              this.waitingAPIResponse = false
            }, 2000);
          })
          .catch(error => {
            if (error.response) {
              this.alertString = `${error.response.data.message}|alert-no|10000::`+Date.now()
            }
            else if (error.request) {
              let msg = 'The request was made but no response was received. Please check your network.'
              this.alertString = `${msg}|alert-no|8000::`+Date.now()
            }
            else {
              let msg = 'Oups ! something went wrong.'
              this.alertString = `${msg}|alert-no|5000::`+Date.now()
            }
            this.waitingAPIResponse = false
          })
    },
    filterUsers() {
      this.filteredUsers = this.userList.filter(user => {
        return user.email.toLowerCase().includes(this.inputText.toLowerCase())
      })
    },
    selectUser(user) {
      this.selectedUser = user
      this.inputText = user.email
      this.filteredUsers = []
      this.$emit('userSelected', user)
    },
    clearSelection() {
      this.selectedUser = null
      this.inputText = ''
      this.filteredUsers = []
      this.$emit('userSelected', null)
    }
  }
}
</script>

<style scoped>
  .input-container{
    position: relative;
  }
  .clear-icon{
    position: absolute;
    right: 0.9em;
    top: 0.7em;
    cursor: pointer;
  }
  .item {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.25em;
  }
  .item:hover{
    background: #cef1f3;
  }
  li{
    list-style-type: none;
  }
  ul{
    background: #dddbdb;
    padding-left: .2em;
    padding: .2em;
  }
  .selected-user {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    padding: 0.5em;
    margin-bottom: 0.5em;
    border-radius: 0.25em;
  }
  .selected-user span:not(.delete-btn) {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5em;
  }
  .delete-btn {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    margin-left: 0.5em;
    color: #a94442;
  }
</style>

<template>
  <div class="cfa-root">
    <c-f-a-nav :alert="alertString" contentClass="position-relative">
      <template v-slot:title>
        <div v-if="waitingAPIResponse == false" class="w-100 pt-5 text-start mt-2 mb-3">
          <h1 class="cfa-section-title"><span class="cfa-primary text-uppercase">{{ this.$route.params.module }} :</span> {{ moduleData.title }}</h1>
        </div>
        <div v-else></div>
      </template>
      <template v-slot:content>
        <div v-if="waitingAPIResponse == false" class="cfa-page">
          <div v-show="moduleData != null" class="d-flex flex-column flex-md-row mt-3 flex-1">
            <div class="col-12 col-md-12 pe-0 pe-md-2">
              <div class="cfa-welcome ml-0">
                <div class="cfa-welcome-video">
                  <c-f-a-video :placeholder="this.moduleData.module_image" :video-link="this.moduleData.module_video" :showLabel="false" maxHeight="35"></c-f-a-video>
                </div>
                <div class="py-3 d-flex justify-content-between">
                  <button class="btn btn-discret cfa-btn-primary">
                    <img width="10" src="../../assets/Icons/All_Icon/Vector-7.png" alt="cfa assets"/>
                    {{ $route.params.module }}
                  </button>
                </div>
                <div class="pb-3 pt-4">
                  <h1 class="cfa-section-title fs-1/2">{{ moduleData.title }}</h1>
                </div>
                <div class="py-3 d-flex">
                  <div class=" d-flex align-items-center">
                    <img width="13" src="../../assets/Icons/All_Icon/Vector-24.png" alt="cfa assets image"/>
                    <span class="ms-2">Durée: <b class="cfa-primary">{{ moduleData.duration }}</b></span>
                  </div>
                  <div class="ms-2 d-flex align-items-center">
                    <img width="13" src="../../assets/Icons/All_Icon/Vector-9.png" alt="cfa assets image"/>
                    <span class="ms-2">Language: <b class="cfa-primary">FRANÇAIS</b></span>
                  </div>
                </div>
                <br>
                <div class="py-3">
                  <h1 class="cfa-section-title fs-1/2">DESCRIPTION</h1>
                  <hr>
                  <p class="w-100">Aperçu du module</p>
                  <p class="w-100" style="color: rgb(38, 36, 36);">
                    {{
                        moduleData.resume
                    }}
                  </p>
                  <br>
                  <p class="d-flex align-items-center">
                    <img width="10" src="../../assets/Icons/All_Icon/Vector-10.png" alt="cfa assets"/>
                    &nbsp;
                    <strong>
                      <span v-for="t in tags" v-bind:key="t">#{{ t.name }}&nbsp;</span>
<!--                      Mot de passe, Couriel, Virus-->
                    </strong>
                  </p>
                  <br>
                  <p class="w-100">Quelle est la cible ?</p>
                  <p class="w-100" style="color: rgb(38, 36, 36);">
                    {{
                      moduleData.target
                    }}
                  </p>
                  <br>
                  <hr>
                </div>
                <div class="py-3">
                  <h1 class="cfa-section-title fs-1/2">RESSOURCES</h1>
                  <div class="mt-4 cfa-welcome cfa-resources-course p-4">
                    <!-- <h3>Semaine 01</h3> -->
                    <div>
                      <div class="d-flex flex-column">

                        <div v-if="linkedRessource.length != 0" >
                          <div v-for="ressource in linkedRessource" v-bind:key="ressource.id" class="cfa-border-top-light py-4 mt-0 d-flex align-items-center justify-content-between">
                            <div>
                              <img width="15" src="../../assets/Icons/All_Icon/Vector-11.png" alt="cfa assets"/>&nbsp;
                              <span><b>Lecture</b> : {{ ressource.title }}</span>
                            </div>
                            <router-link class="linke" :to="{name: 'ResourcesIframe', params: {id: ressource.id, slug: ressource.title.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}}">
                              <button class="btn cfa-btn-primary cfa-btn-discret">ACCÉDER À LA RESSOURCE</button>
                            </router-link>
                          </div>
                        </div>
                        <div v-else class="d-flex justify-content-center align-items-center emptyBlock">
                          <p>AUCUNE RESSOURCE RATTACHÉE A CE MODULE !</p>
                        </div>


<!--                        <div class="cfa-border-top-light py-4 mt-2 d-flex align-items-center justify-content-between">-->
<!--                          <div>-->
<!--                            <img width="15" src="../../assets/Icons/All_Icon/Vector-12.png" alt="cfa assets"/>&nbsp;-->
<!--                            <span><b>Video</b> : Lorem lorem lorem lorem</span>-->
<!--                          </div>-->
<!--                          <span>15 minutes</span>-->
<!--                        </div>-->
<!--                        <div class="cfa-border-top-light py-4 mt-2 d-flex align-items-center justify-content-between">-->
<!--                          <div>-->
<!--                            <img width="15" src="../../assets/Icons/All_Icon/Vector-13.png" alt="cfa assets"/>&nbsp;-->
<!--                            <span><b>Audio</b> : Lorem lorem lorem lorem</span>-->
<!--                          </div>-->
<!--                          <button class="btn cfa-btn-primary cfa-btn-discret">2 QUESTIONS</button>-->
<!--                        </div>-->
<!--                        <div class="cfa-border-top-light py-4 mt-2 d-flex align-items-center justify-content-between">-->
<!--                          <div>-->
<!--                            <img width="15" src="../../assets/Icons/All_Icon/Vector-11.png" alt="cfa assets"/>&nbsp;-->
<!--                            <span><b>Lecture</b> : Lorem lorem lorem lorem</span>-->
<!--                          </div>-->
<!--                          <span>22 minutes</span>-->
<!--                        </div>-->

                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-3">
                  <h1 class="cfa-section-title fs-1/2">CONTENU</h1>
                  <div class="mt-4 cfa-welcome cfa-resources-course p-4">
                    <div class="courseIframe d-flex position-relative">
                      <iframe v-show="!waitingHttpResponse" class="col-12" :src="url" title="CyberForceAcademy - Courses Interface"></iframe>
                      <pre-loader v-show="waitingHttpResponse" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="moduleData == null">
            <div class="d-flex flex-column">
              <img src="@/assets/img/svg/cfa-ud-assets-05.svg" alt="empty content" style="width: 15em;"/>
              <span class="mt-3 fs-5 fw-bold">Oups ! Rien pour le moment.</span>
            </div>
          </div>
        </div>
        <div v-else class="cfa-page">
          <div class="d-flex justify-content-center align-items-center" style="min-height: 70vh">
            <div class="preloader_center d-flex flex-column">
              <img src="../../assets/img/gif/rocket.gif" alt="cfa preloader">
              <p class="pl-3">loading . . .</p>
            </div>
          </div>
        </div>
      </template>
    </c-f-a-nav>
    <c-f-a-footer></c-f-a-footer>
  </div>
</template>

<script>
import CFAFooter from '@/components/nav/CFAFooter.vue'
import CFANav from '@/components/nav/CFANav.vue'
import CFAVideo from '@/components/shared/video/CFAVideo.vue'
import PreLoader from '@/components/shared/PreLoader.vue'
import { getAPI } from '@/axios-api.js'

export default {
  name: 'DashboardView',
  components: {
    CFAFooter,
    CFANav,
    CFAVideo,
    PreLoader
    // DoughnutChart,
    // CourseCard
  },
  data () {
    return {
      /*https://iseazy.com/dl/f8658e755a02432e9235567ea8c68b4e#/slide/IYC8pSktB*/
      url: '',
      waitingHttpResponse: true,
      alertString: '',
      waitingAPIResponse: true,
      moduleData: null,
      resourceData: [],
      tags: [],
      linkedRessource: []
    }
  },
  methods: {
    async getModuleData() {
      try {
        this.waitingAPIResponse = true;
        const [response1, response2, response3] = await Promise.all([
          getAPI.get(`modules/${this.$route.params.id}/`),
          getAPI.get(`ressources`),
          getAPI.get(`tags`),
        ]);
        if(response1.status == 200 && response2.status == 200 && response3.status == 200) {
          this.moduleData = response1.data
          this.url = this.moduleData.link
          this.resourceData = response1.data.results
          this.tags = response3.data.results;
          let modulesTagsId = Object.values(this.moduleData.tags)
          for(let i = 0; i<this.tags.length; i++) {
            // console.log(tags[i].id)
            if(modulesTagsId.includes(this.tags[i].id)) {
              // this.tags.push(this.tags[i].name);
              // console.log(this.tags);
            }
          }

          let filteringResource = [];
          let resources = response2.data.results;
          for(let i=0; i<resources.length; i++) {
            if(resources[i].module == this.$route.params.id) {
              filteringResource.push(resources[i])
            }
          }

          // console.log(filteringResource)
          this.linkedRessource = filteringResource
          this.waitingAPIResponse = false
        }
        else{
          this.waitingAPIResponse = false
          this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
        }
      }
      catch (e) {
        this.alertString = `Une erreur est survenu|alert-no|4000::`+Date.now()
      }
    }
  },
  async mounted() {
    await this.getModuleData()
    document.querySelector('iframe').onload = () => {
      this.waitingHttpResponse = false
    }
    document.getElementById('cfa-nav-bottom-swiper').click()
  }
}
</script>

<style scoped>
.courseIframe iframe{
  padding: 0;
  overflow-y: hidden;
}
.courseIframe{
  width: 100%;
  min-height: 100vh;
  /* margin-top: 5em; */
}
.iframeAction{
  width: 100%;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.cfa-page{

}
.gFolder-container{
  background-color: #cef1f3;
  border-radius: 0.5em;
  height: 4.3em;
  width: 4.6em;
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gBlock{
  align-items: center;
  justify-content: start;
  border-left: 4px solid #25c2cb;
  border-radius: 0.6em;
  padding-left: 2em;
  padding-right: 2em;
  height: 8em !important;
}
.gBlock div:last-of-type p{
  margin: 0;
}
.gBlock div:last-of-type p:first-of-type{
  font-weight: bold;
  font-size: 2em;
}
.cfa-welcome{
  background-color: #fff;
  border-radius: 0.5em;
  padding: .5em 1em;
  /* margin: .5em; */
}
.cfa-warning-deadline{
  border: 2px solid #42cbd0;
  background-color: #d6f6f3;
  color: #20c2c9;
  border-radius: .3em;
  -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
  box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
}
.cfa-alert-d{
  border-bottom: 1px solid #f3f3f3;
  transition: all ease-in .3s;
}
.cfa-alert-d:last-of-type{
  border: none;
}
.cfa-alert-d:hover{
  -webkit-box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
  box-shadow: 4px 18px 60px -20px rgba(6,187,196,0.28);
}
.cfa-state-view{
  border-radius: .5em;
}
.cfa-resources-course{
  box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -webkit-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
  -moz-box-shadow: -1px 1px 29px 20px rgba(245, 246, 247, 0.87);
}
.cfa-border-top-light{
  border-top: 1px solid #ddd;
}
.cfa-border-top-light:first-of-type{
  border-top: none;
}
.emptyBlock{
  width: 100%;
  height: 5em;
  background-color: #fddbdb;
}
</style>
